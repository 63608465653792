/* eslint-disable no-case-declarations */
import _ from 'lodash';
import moment from 'moment-timezone';
import React from 'react';

import { isWithinEditableWindow } from 'common-src/models/ClientTimeLog';
import { moduleTypes } from 'common-src/models/PatientInteraction';
import { validateLabReadingUniqueness, VitalsLabsTypes } from 'common-src/models/PatientLabReading';
import { isDateValid } from 'common-src/utils/dateUtils';

import { CGMEvaluationFieldIds } from 'src/pages/DocumentationIndex/components/CGMEvaluationForm/constants';
import { VitalsAndLabsFieldIds } from 'src/pages/DocumentationIndex/components/VitalsAndLabsForm/constants';

export const textWithMarkdown = (text, textStyle, linkedTextStyle) => {
  const htmlEls = [];

  // Match link patterns like [some link](example.com)
  //   - First group is text up to link or newline
  //   - Second is link text
  //   - Third is link reference
  //   - Fourth is newline
  const hrefRe = /(.*?)(?:\[(.*?)\]\((.*?)\)|(\n))/g;
  let hrefMatch = hrefRe.exec(text);
  let endIndex = 0;

  while (hrefMatch) {
    if (!_.isEmpty(hrefMatch[1])) {
      // If there is leading text, push it in a span
      htmlEls.push(
        <span key={`${hrefMatch[1]}-${endIndex}`} className={textStyle}>{`${hrefMatch[1]}`}</span>,
      );
    }
    if (!_.isEmpty(hrefMatch[2]) || !_.isEmpty(hrefMatch[3])) {
      // If there is a link, push it in an a tag
      htmlEls.push(
        <a key={`${hrefMatch[2]}-${endIndex}`} href={hrefMatch[3]} target="_blank" rel="noreferrer">
          <span className={linkedTextStyle}>{`${hrefMatch[2]}`}</span>
        </a>,
      );
    }
    if (!_.isEmpty(hrefMatch[4])) {
      // If match is a newline, push a break
      htmlEls.push(<br key={`${hrefMatch[4]}-${endIndex}`} />);
    }
    endIndex += hrefMatch[0].length;
    hrefMatch = hrefRe.exec(text);
  }
  const leftover = text.substring(endIndex);
  if (!_.isEmpty(leftover)) {
    // If there's anything leftover, push it in a span
    htmlEls.push(<span key={leftover} className={textStyle}>{`${leftover}`}</span>);
  }
  return htmlEls;
};

export const getFieldKey = (formValues, moduleIdx, sectionIdx, questionIdx) =>
  `${moduleIdx}.sections.${sectionIdx}.contents.${questionIdx}.value`;

export const shouldRenderLabel = (question) =>
  ['radio', 'date', 'checkbox'].includes(question?.type);

const isConditionSatisfied = (contents, condition) => {
  const questionToCheck = _.find(contents, (question) => question.id === condition?.id);

  if (!questionToCheck) return false;

  // check if the validated question is not hidden. In this case condition is not satisfied.
  // eslint-disable-next-line no-use-before-define
  if (questionToCheck.conditions && !checkConditions(contents, questionToCheck.conditions)) {
    return false;
  }

  const questionValues = questionToCheck.value;

  let result = false;
  switch (condition.op) {
    case 'EQ':
      result = Array.isArray(questionValues)
        ? questionValues.includes(condition.value)
        : questionValues === condition.value;

      break;
    case 'EMPTY':
      result = Array.isArray(questionValues) ? _.isEmpty(questionValues) : !String(questionValues);
      break;
    default:
      break;
  }

  return result;
};

const checkConditions = (contents, conditions, shouldAllPass) => {
  if (_.isEmpty(conditions)) return true;

  const conditionsResults = _.map(conditions, (condition) => {
    if ('or' in condition) {
      return checkConditions(contents, condition.or, false);
    }

    if ('and' in condition) {
      return checkConditions(contents, condition.and, true);
    }

    return isConditionSatisfied(contents, condition);
  });

  return shouldAllPass
    ? _.every(conditionsResults, (value) => value === true)
    : _.some(conditionsResults, (value) => value === true);
};

export const shouldExpandContent = (allSections, content) => {
  if (_.isEmpty(allSections) || !content?.conditions) return true;

  const allQuestions = _.reduce(
    allSections,
    (result, value) => {
      result.push(...value.contents);
      return result;
    },
    [],
  );

  return checkConditions(allQuestions, content?.conditions, true);
};

const startEndDatesModuleTypes = [
  {
    id: moduleTypes.HoldRequest.id,
    startDateText: 'Please enter starting date for hold status',
    endDateText: 'Please enter expiration date for hold status',
  },
  {
    id: moduleTypes.CGMAssessment.id,
    startDateText: 'Start date',
    endDateText: 'End date',
  },
];

const validateStartEndDates = (modules) => {
  const moduleTypesWithoutErrors = _.filter(startEndDatesModuleTypes, (moduleType) => {
    const module = _.find(modules, (m) => m.isSelected && m.moduleType === moduleType.id);
    if (!module) return true;

    const { sections } = module;
    let startingDate;
    let endingDate;
    sections.forEach((s) => {
      startingDate = s.contents.find((c) => c.text === moduleType.startDateText)?.value;
      endingDate = s.contents.find((c) => c.text === moduleType.endDateText)?.value;
    });

    if (!startingDate) return true;

    return !moment(endingDate).isBefore(moment(startingDate));
  });

  return startEndDatesModuleTypes.length === moduleTypesWithoutErrors.length;
};

/**
 * General validation function for documentation modules
 * @param modules
 * @returns {{hasMissingFields: boolean, fieldErrors: Map, validationError: null}}
 * hasMissingFields - shows whether there are missing fields or not
 * fieldErrors - map with keys equal to question ids and value the string error attached to this question
 */
export const checkModuleRequiredFields = (modules) => {
  let hasMissingFields = false;
  let validationError = null;
  const fieldErrors = new Map();
  modules
    .filter((module) => !_.isEmpty(module))
    .forEach((module) => {
      module.sections.forEach((section) => {
        section.contents.forEach((content) => {
          const { id, required, type, value, text, minValue, maxValue, maxChars, errorMessage } =
            content;

          if (
            !shouldExpandContent(module.sections, section) ||
            !shouldExpandContent(module.sections, content)
          )
            return;

          let hasError = false;
          switch (type) {
            case 'integer':
            case 'currency':
            case 'number':
              hasError = value && Number.isNaN(value);
              break;
            case 'date':
              hasError = value && !isDateValid(value);
              break;
            default:
              break;
          }

          if (hasError) {
            validationError = `Value for ${text} should be a valid ${type}!`;
            fieldErrors.set(id, validationError);
          }

          if (maxChars && ['text', 'textArea'].includes(type)) {
            const hasReachedCharLimit = !value ? false : String(value).length > maxChars;

            if (hasReachedCharLimit) {
              fieldErrors.set(id, `Should be ${maxChars} characters at most`);
            }
          }

          if (!required) return;

          let isFilled = false;
          switch (type) {
            case 'text':
            case 'radio':
            case 'textArea':
            case 'date':
              isFilled = !!value;
              break;
            case 'integer':
            case 'currency':
            case 'number':
              const hasValue = !!value || value === 0;
              const minValueCondition = hasValue ? Number(value) >= (minValue ?? 0) : false;
              const maxValueCondition = hasValue
                ? Number(value) <= (maxValue ?? Number.MAX_SAFE_INTEGER)
                : false;

              isFilled = hasValue && minValueCondition && maxValueCondition;
              break;
            case 'checkbox':
            case 'multiSelect':
              isFilled = !_.isEmpty(value);
              break;
            case 'bool':
              isFilled = typeof value === 'boolean';
              break;
            default:
              break;
          }

          if (!isFilled) {
            hasMissingFields = true;
            fieldErrors.set(id, errorMessage || 'Missing value');
          }
        });
      });
    });

  return { hasMissingFields, validationError, fieldErrors };
};

const checkVitalsAndLabsRequiredFields = (vitalsAndLabs, labResults, lastA1c) => {
  const errors = new Map();
  let generalError = '';

  if (_.isEmpty(vitalsAndLabs)) {
    return { errors, generalError };
  }

  if ((vitalsAndLabs.weight || vitalsAndLabs.height) && !vitalsAndLabs.weightDateObserved) {
    errors.set(VitalsAndLabsFieldIds.weightDateContainer, 'Date is required');
    generalError = 'Please fill the required inputs';
  }

  if (vitalsAndLabs.hbA1c && !vitalsAndLabs.hbA1cDateObserved) {
    errors.set(VitalsAndLabsFieldIds.hbA1cDateContainer, 'Date is required');
    generalError = 'Please fill the required inputs';
  }

  if (
    lastA1c?.readingValue === vitalsAndLabs.hbA1c &&
    lastA1c?.readingDate === moment(vitalsAndLabs.hbA1cDateObserved).format('YYYY-MM-DD')
  ) {
    return { errors, generalError };
  }

  if (vitalsAndLabs.hbA1c && vitalsAndLabs.hbA1cDateObserved) {
    const isUniqueRecord = validateLabReadingUniqueness(labResults, {
      typeId: VitalsLabsTypes.HbA1c.value,
      readingDate: moment(vitalsAndLabs.hbA1cDateObserved).format('YYYY-MM-DD'),
      readingValue: vitalsAndLabs.hbA1c,
      patientReported: true,
    });

    if (!isUniqueRecord) {
      errors.set(
        VitalsAndLabsFieldIds.hbA1cContainer,
        'The lab result already exists for this date',
      );
      generalError = 'Uniqueness error';
    }
  }

  return { errors, generalError };
};

const checkCgmEvaluationRequiredFields = (cgmEvaluation) => {
  const errors = new Map();
  let generalError = '';

  if (_.isEmpty(cgmEvaluation)) {
    return { errors, generalError };
  }

  if (!cgmEvaluation.startDate) {
    errors.set(CGMEvaluationFieldIds.startDateContainer, 'Start date is required');
    generalError = 'Please fill the required inputs';
  }

  if (!cgmEvaluation.endDate) {
    errors.set(CGMEvaluationFieldIds.endDateContainer, 'End date is required');
    generalError = 'Please fill the required inputs';
  }

  return { errors, generalError };
};

export const getDocumentFormErrors = (
  modules,
  documentation,
  serviceAt,
  isServiceAtValid,
  showBillingInfo,
  timeLogs,
  regalTaskIds,
  awscCallIds,
  requireTimeLog,
  isEmbedded,
  requireLinkVisit,
  sdoh,
  vitalsAndLabs,
  labResults,
  lastA1c,
  cgmEvaluation,
) => {
  if (!documentation.isDraft) {
    return { generalError: 'Documentation has already been submitted!', fieldErrors: new Map() };
  }

  const selectedModules = _.filter(modules, 'isSelected');
  if (_.isEmpty(selectedModules)) {
    return { generalError: 'At least one module should be selected', fieldErrors: new Map() };
  }

  const { errors: vitalAndLabsErrors, generalError: vitalsAndLabsGeneralError } =
    checkVitalsAndLabsRequiredFields(vitalsAndLabs, labResults, lastA1c);
  if (vitalAndLabsErrors.size !== 0) {
    return { generalError: vitalsAndLabsGeneralError, fieldErrors: vitalAndLabsErrors };
  }

  const { errors: cgmEvaluationErrors, generalError: cgmEvaluationGeneralError } =
    checkCgmEvaluationRequiredFields(cgmEvaluation);
  if (cgmEvaluationErrors.size !== 0) {
    return { generalError: cgmEvaluationGeneralError, fieldErrors: cgmEvaluationErrors };
  }

  const { validationError, hasMissingFields, fieldErrors } = checkModuleRequiredFields([
    ...selectedModules,
    sdoh,
  ]);

  if (!_.isEmpty(fieldErrors)) {
    return { generalError: 'Please fill the required inputs', fieldErrors };
  }

  if (hasMissingFields) {
    return { generalError: 'Missing required form fields', fieldErrors: new Map() };
  }

  if (validationError) {
    return { generalError: validationError, fieldErrors: new Map() };
  }

  if (!validateStartEndDates(modules)) {
    return {
      generalError: 'Ending date should not be before starting date',
      fieldErrors: new Map(),
    };
  }

  if (showBillingInfo || isEmbedded) {
    if (!isServiceAtValid) {
      return { generalError: 'Set valid date of care', fieldErrors: new Map() };
    }

    if (_.isEmpty(serviceAt)) {
      return { generalError: "The date of care can't be empty", fieldErrors: new Map() };
    }

    if (!isWithinEditableWindow(serviceAt)) {
      return {
        generalError: "The date of care can't be set to more than 3 days in the past",
        fieldErrors: new Map(),
      };
    }

    if (
      requireTimeLog &&
      ![_.isEmpty(regalTaskIds), _.isEmpty(awscCallIds), _.isEmpty(timeLogs)].includes(false)
    ) {
      return {
        generalError: 'Please link the conversation event or add manual time',
        fieldErrors: new Map(),
      };
    }

    const erroredTimeLog = timeLogs.find((tl) => !tl.category || !tl.timeSpent);
    if (erroredTimeLog) {
      return {
        generalError: 'Missing required form fields',
        fieldErrors: new Map([['timeLogIndex', timeLogs.indexOf(erroredTimeLog)]]),
      };
    }

    if (isEmbedded && requireLinkVisit) {
      return 'Please link the scheduled visit';
    }
  }

  return { generalError: null, fieldErrors: new Map() };
};

export const getFinalizedDocs = (modules) =>
  modules
    .filter((m) => m.isSelected)
    .map((m) => {
      m.sections = m.sections.map((s) => {
        s.isActive = shouldExpandContent(m.sections, s);
        s.contents = s.contents.map((c) => {
          c.isActive = !s.isActive ? false : shouldExpandContent(m.sections, c);

          return c;
        });

        return s;
      });

      return m;
    });
