import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';

import styles from './SettingsNav.module.scss';

const SettingsNav = ({ canSetFeatureFlags }) => (
  <nav id="settings-nav" className={[styles.nav, 'flex-column', 'p-l-40'].join(' ')}>
    <NavLink
      to="/dashboard-client/settings/my-account"
      className={({ isActive }) => (isActive ? styles.active : '')}
    >
      Profile
    </NavLink>
    {canSetFeatureFlags && (
      <NavLink
        to="/dashboard-client/settings/feature-flags"
        className={({ isActive }) => (isActive ? styles.active : '')}
      >
        Feature flags
      </NavLink>
    )}
  </nav>
);

SettingsNav.propTypes = {
  canSetFeatureFlags: PropTypes.bool,
};

export default SettingsNav;
