import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Input, Select } from 'common-src/components/base';
import {
  CREATABLE_TASK_TYPE_REASONS,
  PatientTaskStatus,
  TaskType,
} from 'common-src/models/PatientTask';
import { createPatientTask } from 'common-src/models/PatientTask/actions';

import usePopup from 'src/hooks/usePopup';
import BasePopup from 'src/popups/BasePopup';

import styles from './AddTaskPopup.module.scss';
import { initialState } from './constants';

const AddTaskPopup = ({ patientId, onClose }) => {
  const dispatch = useDispatch();

  const [data, setData] = useState(initialState);

  const { renderButtons } = usePopup();

  const onSubmitHandler = (loadingCallback, successCallback, errorCallback) => {
    loadingCallback();
    dispatch(
      createPatientTask(
        {
          patientId,
          typeId: TaskType.Administrative.value,
          reasonId: data.reason.value,
          subject: data.subject,
          status: PatientTaskStatus.New.value,
        },
        {
          successBlock: () => {
            successCallback('Task created!');
            onClose(true);
          },
          errorBlock: (err) => errorCallback(err),
        },
      ),
    );
  };

  return (
    <BasePopup id="add-task" open onClose={onClose} title="Add task">
      <div className={styles.wrapper}>
        <Select
          id="reason-select"
          label="Reason"
          placeholder="Select reason"
          options={CREATABLE_TASK_TYPE_REASONS}
          onChange={(reason) => setData((prev) => ({ ...prev, reason }))}
          value={_.find(CREATABLE_TASK_TYPE_REASONS, { value: data.reason?.value })}
        />
        {data.reason && (
          <Input
            id="subject-input"
            label="Subject"
            onTextChange={(subject) => setData((prev) => ({ ...prev, subject }))}
            value={data.subject}
            placeholder="Enter subject"
          />
        )}
      </div>
      {renderButtons({
        containerClassName: styles.buttonsContainer,
        onClose,
        onSubmit: onSubmitHandler,
        isSubmitEnabled: data.reason,
        submitButtonText: 'Save and close',
      })}
    </BasePopup>
  );
};

AddTaskPopup.propTypes = {
  patientId: PropTypes.number,
  onClose: PropTypes.func.isRequired,
};

export default AddTaskPopup;
