import _ from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { CommonIcons } from 'common-src/assets/Icons';
import { DatePickerNew, RequestHandlerScreen, Select } from 'common-src/components/base';
import { restRequestMultiple } from 'common-src/features/rest/actions';
import {
  ACTIVE_STATUSES,
  getPatientCoverages,
  getPatientCoveragesRequest,
  getPlanCategory,
  INACTIVE_STATUSES,
} from 'common-src/models/PatientCoverage';
import {
  createPatientCoverageHierarchy,
  getCoverageHierarchyRequest,
  getPatientCoverageHierarchies,
  updatePatientCoverageHierarchy,
} from 'common-src/models/PatientCoverageHierarchy';
import { getClosestDate, getDateWithTimeZone } from 'common-src/utils/dateUtils';

import { ModalType } from 'src/components/base/ModalGroup';
import { BaseTable, TablePagination, TextItem } from 'src/components/table';
import { ResultsCount } from 'src/components/table/TablePagination/constants';
import { openModal } from 'src/features/modals';
import useAlert from 'src/hooks/useAlert';
import useCustomSelector from 'src/hooks/useCustomSelector';
import useFormButtons from 'src/hooks/useFormButtons';
import useInterval from 'src/hooks/useInterval';
import useRequestLoading from 'src/hooks/useRequestLoading';

import { Operation, TableHeaders } from './constants';
import {
  findClosestPriorityByDate,
  getCreatePayload,
  getRequestOperations,
  validateCoverageHierarchy,
} from './helpers';
import styles from './PayorPriority.module.scss';

const newDateOption = Object.freeze({
  value: 'New',
  label: 'New',
  icon: CommonIcons.calendarNewIcon,
});

const PayorPriority = ({ patientId, actionsAllowed }) => {
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [paginationData, setPaginationData] = useState({ from: 0, to: undefined });
  const [effectiveDate, setEffectiveDate] = useState(null);
  const [forceOpenDatePicker, setForceOpenDatePicker] = useState(false);

  const patientCoverages = useCustomSelector((state) => getPatientCoverages(state, patientId));
  const coveragePriorities = useCustomSelector((state) =>
    getPatientCoverageHierarchies(state, patientId),
  );

  const isNewEffectiveDateSelected = useMemo(() => {
    const allEffectiveDates = coveragePriorities.map((cp) =>
      getDateWithTimeZone(cp.effectiveDate, 'MM/DD/YYYY'),
    );

    return !allEffectiveDates.includes(effectiveDate);
  }, [effectiveDate, coveragePriorities]);

  const { showAlert, AlertType } = useAlert();

  const { isRequesting } = useRequestLoading([
    getPatientCoveragesRequest({ patientId }),
    getCoverageHierarchyRequest({ patientId }),
  ]);

  useInterval(() => {
    dispatch(getPatientCoveragesRequest({ patientId, sort_by: 'desc', limit: 5 }, {}));
    dispatch(getCoverageHierarchyRequest({ patientId, sort_by: 'desc', limit: 5 }, {}));
  });

  const getSubmitCallback = (isEdit, setIsLoading, setIsEditMode) => ({
    successBlock: () => {
      setIsLoading(false);
      setIsEditMode(false);
      showAlert(
        AlertType.Success,
        '',
        `You have successfully ${isEdit ? 'updated' : 'created'} payor priority.`,
      );
    },
    errorBlock: (err) => {
      setIsLoading(false);
      showAlert(AlertType.Error, '', err);
    },
  });

  const onSubmitHandler = (setIsLoading, setIsEditMode) => {
    const err = validateCoverageHierarchy(data);

    if (err) {
      showAlert(AlertType.Error, '', err);
      return;
    }

    setIsLoading(true);
    if (isNewEffectiveDateSelected) {
      dispatch(
        createPatientCoverageHierarchy(
          getCreatePayload(data, effectiveDate, patientId),
          getSubmitCallback(false, setIsLoading, setIsEditMode),
        ),
      );
    } else {
      const operations = getRequestOperations(data, effectiveDate, coveragePriorities, patientId);
      const requests = [];
      if (!_.isEmpty(operations[Operation.Create])) {
        requests.push(createPatientCoverageHierarchy(operations[Operation.Create]));
      }

      if (!_.isEmpty(operations[Operation.Update])) {
        requests.push(updatePatientCoverageHierarchy(operations[Operation.Update]));
      }

      dispatch(
        restRequestMultiple({
          restRequests: requests,
          ...getSubmitCallback(true, setIsLoading, setIsEditMode),
        }),
      );
    }
  };

  const { isEditMode, renderContent, setInitialData, setIsLoading, setIsEditMode } = useFormButtons(
    'Hierarchy',
    setData,
    () => onSubmitHandler(setIsLoading, setIsEditMode),
    data,
  );

  const activeCoverages = useMemo(() => {
    const groupedCoveragePriorities = _.groupBy(coveragePriorities, 'ref.patientCoverageId');
    const groupedCoveragesByDate = _.mapValues(groupedCoveragePriorities, (group) => {
      const sortedGroup = _.orderBy(group, ['effectiveDate'], ['desc']);

      return _.filter(
        sortedGroup,
        (item) =>
          moment(effectiveDate).startOf('day').isSameOrAfter(moment(item.effectiveDate)) &&
          (!item.patientCoverage?.endDate ||
            moment(effectiveDate)
              .startOf('day')
              .isSameOrBefore(moment(item.patientCoverage?.endDate).startOf('day'))) &&
          item?.effectiveTo === null,
      );
    });

    const coveragePrioritiesByDate = _.compact(
      _.map(groupedCoveragesByDate, (group) => (group.length > 0 ? group[0] : null)),
    );

    const coveragesToFilter =
      effectiveDate && !isEditMode ? coveragePrioritiesByDate : coveragePriorities;

    const allCoverageIds = coveragesToFilter.map((cp) => cp.patientCoverage?.id);
    const uniqueCoverageIds = _.uniq(allCoverageIds);

    // 1. All active coverage plans
    // 2. Selected effective date should be after plan start date
    // 3. Selected effective date should be after priority effective date (in non-edit mode)
    return patientCoverages.filter(
      (c) =>
        (ACTIVE_STATUSES.includes(c.status) ||
          (INACTIVE_STATUSES.includes(c.status) &&
            moment(effectiveDate).startOf('day').isBefore(moment(c.endDate).endOf('day')))) &&
        (!effectiveDate || moment(effectiveDate).endOf('day').isSameOrAfter(moment(c.startDate))) &&
        (isEditMode || uniqueCoverageIds.includes(c.id)),
    );
  }, [patientCoverages, coveragePriorities, effectiveDate, isEditMode]);

  const hierarchyOptions = useMemo(
    () =>
      Array.from({ length: Math.max(activeCoverages.length + 1, 2) }, (_, i) => i).map((item) => ({
        label: item,
        value: item,
      })),
    [activeCoverages.length],
  );

  const effectiveDatesOptions = useMemo(() => {
    if (_.isEmpty(coveragePriorities)) return [];

    const allEffectiveDates = coveragePriorities.map((cp) =>
      getDateWithTimeZone(cp.effectiveDate, 'MM/DD/YYYY'),
    );

    // effective date is newly selected from date picker
    if (effectiveDate && isNewEffectiveDateSelected) {
      // push the date as valid option in edit or remove it in non-edit mode
      if (isEditMode) {
        allEffectiveDates.push(effectiveDate);
      } else {
        setEffectiveDate(null);
      }
    }

    const sortedDates = allEffectiveDates.sort((a, b) => new Date(b) - new Date(a));

    const uniqueDatesOptions = _.uniq(sortedDates).map((date) => ({
      value: date,
      label: date,
    }));

    if (isEditMode) {
      uniqueDatesOptions.push(newDateOption);
    }

    return uniqueDatesOptions;
  }, [coveragePriorities, patientCoverages, isEditMode, effectiveDate]);

  useEffect(() => {
    if (effectiveDate || _.isEmpty(coveragePriorities)) return;

    const allEffectiveDates = coveragePriorities.map((cp) =>
      getDateWithTimeZone(cp.effectiveDate, 'MM/DD/YYYY'),
    );

    setEffectiveDate(getClosestDate(_.uniq(allEffectiveDates)));
  }, [effectiveDate, coveragePriorities]);

  useEffect(() => {
    const data = activeCoverages.map((c) => {
      const priority = findClosestPriorityByDate(coveragePriorities, c, effectiveDate);

      return {
        id: priority?.id ?? Math.random(),
        hierarchy: priority?.hierarchy ?? null,
        coverageId: c.id,
      };
    });

    setInitialData(data);
    setData(data);
  }, [activeCoverages, coveragePriorities, effectiveDate]);

  const onChangeHierarchy = (option, item) => {
    const dataCopy = JSON.parse(JSON.stringify(data));
    const objIndex = dataCopy.findIndex((obj) => obj.id === item.id);

    if (objIndex === -1) return;

    dataCopy[objIndex].hierarchy = option.value;
    setData(dataCopy);
  };

  const openDatePicker = () => {
    setForceOpenDatePicker(true);
    setTimeout(() => {
      setForceOpenDatePicker(false);
    }, 0);
  };

  const onChangeEffectiveDate = (option) => {
    // clear option
    if (!option) {
      setEffectiveDate(null);
      return;
    }

    // new date option case
    if (option.value === newDateOption.value) {
      openDatePicker();
      return;
    }

    setEffectiveDate(option.value);
  };

  const renderHierarchy = (coverageId) => {
    const priority = data.find((item) => item.coverageId === coverageId);

    if (isEditMode) {
      return (
        <Select
          id={`hierarchy-${priority?.id}`}
          options={hierarchyOptions}
          onChange={(option) => onChangeHierarchy(option, priority)}
          value={hierarchyOptions.find((op) => op.value === (priority?.hierarchy ?? 0)) || null}
          withError={false}
          text={Number.isInteger(priority?.hierarchy) ? priority.hierarchy : '0'}
          placeholder="-"
          size="small"
        />
      );
    }

    return (
      <TextItem
        isActive
        text={Number.isInteger(priority?.hierarchy) ? priority.hierarchy : '0'}
        disableTooltip
      />
    );
  };

  const getData = () =>
    activeCoverages.map((coverage) => ({
      payorName: (
        <TextItem
          isActive
          isUnderlined={ACTIVE_STATUSES.includes(coverage.status)}
          disableTooltip
          text={coverage.payorName}
          classNames={[styles.tableCell]}
          onClick={() => {
            if (!ACTIVE_STATUSES.includes(coverage.status)) return;
            dispatch(openModal(ModalType.EDIT_PLAN, { planId: coverage.id }));
          }}
        />
      ),
      planName: <TextItem isActive text={coverage.planName || '-'} disableTooltip />,
      payorType: <TextItem isActive text={getPlanCategory(coverage.payorType)} disableTooltip />,
      startDate: (
        <TextItem
          isActive
          text={coverage.startDate ? moment(coverage.startDate).format('MM/DD/YYYY') : '-'}
          disableTooltip
        />
      ),
      endDate: (
        <TextItem
          isActive
          text={coverage.endDate ? moment(coverage.endDate).format('MM/DD/YYYY') : '-'}
          disableTooltip
        />
      ),
      hierarchy: renderHierarchy(coverage.id),
    }));

  if (isRequesting) {
    return <RequestHandlerScreen isRequesting requestingText="Pulling priorities..." />;
  }

  return (
    <>
      <div className={styles.subheader}>
        <div className={styles.dateContainer}>
          <span className={styles.text}>Priority effective date</span>
          <Select
            id="priority-effective-date-filter"
            classNames={[styles.select]}
            options={effectiveDatesOptions}
            onChange={onChangeEffectiveDate}
            value={effectiveDatesOptions.find((op) => op?.value === effectiveDate) || null}
            withError={false}
            placeholder="MM/DD/YYYY"
            size="small"
          />
          <DatePickerNew
            id="new-effective-date-picker"
            classNames={[styles.pickerContainer]}
            inputClassNames={[styles.pickerInput]}
            forceOpen={forceOpenDatePicker}
            onDateSelected={(date) => {
              const selectedDate = date ? moment(date).format('MM/DD/YYYY') : null;
              setEffectiveDate(selectedDate);
            }}
          />
        </div>
        {renderContent(true, !actionsAllowed)}
      </div>
      <BaseTable
        headers={TableHeaders}
        data={getData()}
        name="payor-priority"
        classNames={[
          'medical-table-new',
          activeCoverages?.length <= ResultsCount[25] ? 'no-pagination' : '',
        ]}
        emptyText="No payor priorities"
        emptyClassNames={['medical-table-empty']}
        initialSortColumn={{
          column: 'hierarchy',
          label: 'Hierarchy',
          width: '15%',
          sortBy: 'text',
        }}
        paginationData={paginationData}
      />
      <TablePagination onChangePageHandler={setPaginationData} results={activeCoverages} />
    </>
  );
};

PayorPriority.propTypes = {
  patientId: PropTypes.number,
  actionsAllowed: PropTypes.bool,
};

export default PayorPriority;
