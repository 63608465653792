import _ from 'lodash';
import moment from 'moment-timezone';

export const getMostRecentHierarchy = (hierarchies, coveragePlan) => {
  if (!Array.isArray(hierarchies) || hierarchies.length === 0) return null;

  const now = moment();

  return _.sortBy(
    _.filter(
      hierarchies,
      (hierarchy) =>
        moment(hierarchy.effectiveDate).startOf('day').isSameOrBefore(now) &&
        moment(coveragePlan.startDate).startOf('day').isSameOrBefore(now),
    ),
    'effectiveDate',
  )[0];
};
