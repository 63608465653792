import 'react-datepicker/dist/react-datepicker.css';
import './styles.css';

import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';

import { CommonIcons } from 'common-src/assets/Icons';
import { Tooltip } from 'common-src/components/base';

import { InfoItem } from 'src/components/elements';

import { defaultTimeValues } from './constants';
import styles from './DatePicker.module.scss';
import { getFormattedDate, getTimeValues } from './helpers';
import { DateInput, TimeInput } from './inputs';

const CustomDatePicker = ({
  id,
  value,
  width,
  header,
  minDate,
  maxDate,
  disabled,
  errorText,
  classNames,
  placeholder,
  startDate,
  endDate,
  warningText,
  iconSrc,
  tooltipText,
  description,
  size,
  containerId,
  withTime = false,
  readOnly = false,
  required = false,
  isMonthly = false,
  onBlur = () => {},
  textClassNames = [],
  inputClassNames = [],
  selectsRange = false,
  onDateSelected = () => {},
  onRangeSelected = () => {},
  forceOpen = false,
}) => {
  const customInputRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(withTime ? defaultTimeValues : null);

  const error = errorMsg || errorText;

  const isSmall = size === 'small';

  const wrapperClasses = [styles.wrapper];
  if (classNames) {
    wrapperClasses.push(...classNames);
  }
  if (disabled) {
    wrapperClasses.push(styles.disabled);
  }

  useEffect(() => {
    setDate(getFormattedDate(value));

    if (withTime) {
      setTime(getTimeValues(getFormattedDate(value)));
    }
  }, [value]);

  useEffect(() => {
    if (!selectsRange || !startDate || !endDate) return;

    setDate([moment(startDate).valueOf(), moment(endDate).valueOf()]);
  }, [startDate, endDate]);

  useEffect(() => {
    if (forceOpen && !isOpen) {
      setIsOpen(true);
    }
  }, [forceOpen]);

  const getDateFormat = () => {
    if (withTime) {
      return 'MM/dd/yyyy hh:mm aa';
    }

    return isMonthly ? 'MM/yyyy' : 'MM/dd/yyyy';
  };

  const onClickHandler = () => {
    if (disabled) return;

    setIsOpen(true);
  };

  const onClearHandler = () => {
    if (disabled) return;

    setDate(null);
    onDateSelected(null);
    onRangeSelected([null, null]);
    setTime(defaultTimeValues);
    setIsOpen(false);
    setErrorMsg(null);
  };

  const onSaveHandler = () => {
    if (Array.isArray(date) && !date.includes(null)) {
      onRangeSelected(date);
    }
    onDateSelected(date);
    setIsOpen(false);
  };

  const onClickOutsideHandler = () => {
    setTime(getTimeValues(value));
    onSaveHandler();
  };

  const onBlurHandler = (v) => {
    if (isOpen) return;

    onBlur(v);

    if (v === '') {
      setDate(null);
      return;
    }

    if (Array.isArray(v)) {
      setDate(v);
      onRangeSelected(v);
    }

    if (v && !moment(v).isSame(value)) {
      const formattedDate = getFormattedDate(v);
      setDate(formattedDate);
      onDateSelected(formattedDate);
      setTime(getTimeValues(formattedDate));
    }
  };

  const onDateChangeHandler = (date) => {
    setDate(date);
    setErrorMsg(null);
  };

  const renderDescription = () => {
    if (!description) return null;

    return (
      <span className={[styles.description, 'font-w-400', 'font-s-12'].join(' ')}>
        {description}
      </span>
    );
  };

  const renderError = () => {
    const showWarning = !error && warningText;

    return (
      <span
        className={[
          styles.errorText,
          isSmall ? 'font-s-10' : 'font-s-12',
          showWarning ? styles.warning : '',
        ].join(' ')}
      >
        {error || warningText}
      </span>
    );
  };

  const renderHeader = () => {
    if (!header) return null;

    return (
      <div className={[styles.headerContainer, 'vertically-centered', 'm-b-6', 'gap-6'].join(' ')}>
        <span
          className={[
            styles.header,
            'font-w-500',
            isSmall ? 'font-s-12' : 'font-s-14',
            error ? styles.withError : '',
          ].join(' ')}
        >{`${header}${required && !tooltipText ? '*' : ''}`}</span>
        {tooltipText && (
          <Tooltip title={tooltipText} classNames={['col-auto']}>
            <img src={CommonIcons.tooltipIcon} alt="tooltip-icon" />
          </Tooltip>
        )}
      </div>
    );
  };

  const renderContent = () => {
    if (readOnly) {
      return (
        <InfoItem
          title={header}
          content={moment(value).format('MM/DD/YYYY')}
          textId={id}
          isSmall={isSmall}
        />
      );
    }

    return (
      <>
        {renderHeader()}
        <DatePicker
          open={isOpen}
          fixedHeight
          showTimeInput
          selected={selectsRange ? date?.[0] : date || getFormattedDate(value)}
          minDate={minDate}
          maxDate={maxDate}
          disabled={disabled}
          timeInputLabel={null}
          showPopperArrow={false}
          endDate={Array.isArray(date) ? date[1] : moment(endDate).valueOf()}
          startDate={Array.isArray(date) ? date[0] : moment(startDate).valueOf()}
          selectsRange={selectsRange}
          onYearChange={onDateChangeHandler}
          onChange={onDateChangeHandler}
          shouldCloseOnSelect={false}
          showMonthYearPicker={isMonthly}
          showFullMonthYearPicker={isMonthly}
          customInput={
            <DateInput
              idValue={id}
              withTime={withTime}
              ref={customInputRef}
              classNames={[
                styles.dateInput,
                error ? styles.withError : '',
                'vertically-centered',
                'm-b-1',
                'border-r-6',
                ...inputClassNames,
              ]}
              label={placeholder}
              required={required}
              isMonthly={isMonthly}
              isRange={selectsRange}
              setErrorMsg={setErrorMsg}
              onBlurHandler={onBlurHandler}
              onClearHandler={onClearHandler}
              onClickHandler={onClickHandler}
              disabled={disabled}
              iconSrc={iconSrc}
              textClassNames={textClassNames}
              isSmall={isSmall}
            />
          }
          dateFormat={getDateFormat()}
          customTimeInput={
            <TimeInput
              time={time}
              setTime={setTime}
              withTime={withTime}
              onClearHandler={onClearHandler}
              onSaveHandler={onSaveHandler}
            />
          }
          onClickOutside={onClickOutsideHandler}
        />
        {renderDescription()}
        {renderError()}
      </>
    );
  };

  return (
    <div id="date-picker" className={wrapperClasses.join(' ')} style={{ width: width || '100%' }}>
      <div id={containerId}>{renderContent()}</div>
    </div>
  );
};

CustomDatePicker.propTypes = {
  onDateSelected: PropTypes.func,
  placeholder: PropTypes.string,
  classNames: PropTypes.arrayOf(PropTypes.string),
  isMonthly: PropTypes.bool,
  disabled: PropTypes.bool,
  withTime: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.any,
  id: PropTypes.string,
  width: PropTypes.string,
  header: PropTypes.string,
  errorText: PropTypes.string,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  inputClassNames: PropTypes.arrayOf(PropTypes.string),
  onRangeSelected: PropTypes.func,
  selectsRange: PropTypes.bool,
  startDate: PropTypes.any,
  endDate: PropTypes.any,
  onBlur: PropTypes.func,
  tooltipText: PropTypes.string,
  warningText: PropTypes.string,
  readOnly: PropTypes.bool,
  iconSrc: PropTypes.string,
  textClassNames: PropTypes.arrayOf(PropTypes.string),
  description: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium']),
  containerId: PropTypes.string,
  forceOpen: PropTypes.bool,
};

export default CustomDatePicker;
