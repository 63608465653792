import moment from 'moment-timezone';

import { MemberIdValidationByPayor, Payors } from './constants';

export const validatePatientCoverage = (field, value, data) => {
  let errMsg = '';
  switch (field) {
    case 'startDate':
      if (value === undefined) {
        errMsg = 'Invalid date format';
      } else if (!value) {
        errMsg = 'Date should not be empty';
      } else if (value && moment(value).isAfter(data.endDate)) {
        errMsg = 'Start date should be before end date';
      }
      break;
    case 'endDate':
      if (value === undefined) {
        errMsg = 'Invalid date format';
      } else if (value && moment(value).isBefore(data.startDate)) {
        errMsg = 'End date should be after start date';
      }
      break;
    case 'subscriberFirstName':
      if (!value) {
        errMsg = 'First Name should not be empty';
      }
      break;
    case 'subscriberLastName':
      if (!value) {
        errMsg = 'Last Name should not be empty';
      }
      break;
    case 'subscriberDateOfBirth':
      if (value === undefined) {
        errMsg = 'Invalid date format';
      } else if (!value) {
        errMsg = 'Date should not be empty';
      } else if (value && moment(value).format('YYYY-MM-DD') > moment().format('YYYY-MM-DD')) {
        errMsg = 'Date should not be in the future';
      }
      break;
    case 'memberId': {
      if ((data.payorId === Payors.Medicare.value && (data.partA || !data.partB)) || !value) {
        break;
      }

      const res = MemberIdValidationByPayor[data.payorId];
      if (!res) break;

      if (!value.match(res.regex)) {
        errMsg = res.msg;
      }

      break;
    }
    default:
      errMsg = '';
  }

  return { [field]: errMsg };
};
