import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Input, Select } from 'common-src/components/base';
import { UsStatesList } from 'common-src/constants/usStates';
import { validatePatient } from 'common-src/models/Patient';

import usePopup from 'src/hooks/usePopup';
import BasePopup from 'src/popups/BasePopup';

import { getIsButtonEnabled } from './helpers';
import styles from './PatientAddressPopup.module.scss';

const stateOptions = _.map(UsStatesList, (option) => ({ value: option, label: option }));

const PatientAddressPopup = ({ open, onClose, onSubmit }) => {
  const [address, setAddress] = useState({
    street1: '',
    street2: '',
    city: '',
    state: null,
    zipcode: '',
  });

  const { errors, setErrors, renderButtons } = usePopup();

  const getSelectValue = (value, options) =>
    value ? _.find(options, (option) => option.value === value) : null;

  const onChangeHandler = (key, value) => {
    setErrors((prev) => ({ ...prev, ...validatePatient(key, value) }));
    setAddress((prev) => ({ ...prev, [key.split('.')[1]]: value }));
  };

  const onSubmitHandler = (loadingCallback) => {
    loadingCallback();
    onSubmit(address);
  };

  return (
    <BasePopup
      id="patient-address"
      open={open}
      onClose={onClose}
      title="Enter and confirm patient address"
    >
      <Input
        id="street-input"
        classNames={[styles.input, 'm-t-16']}
        errorText={errors['shippingAddress.street1']}
        label="Street address"
        onTextChange={(text) => onChangeHandler('shippingAddress.street1', text)}
        value={address.street1}
        placeholder="Enter street address"
        required
      />
      <Input
        id="street2-input"
        classNames={[styles.input]}
        errorText={errors['shippingAddress.street2']}
        label="Apt, unit, ste, etc."
        onTextChange={(text) => onChangeHandler('shippingAddress.street2', text)}
        value={address.street2}
        placeholder="Enter text"
      />
      <div className={styles.secondaryContainer}>
        <Input
          id="city-input"
          classNames={[styles.input]}
          errorText={errors['shippingAddress.city']}
          label="City"
          onTextChange={(text) => onChangeHandler('shippingAddress.city', text)}
          value={address.city}
          placeholder="Enter city name"
          required
        />
        <Select
          id="state-select"
          classNames={[styles.input]}
          errorText={errors['shippingAddress.state']}
          label="State"
          placeholder="Select state"
          options={stateOptions}
          onChange={(option) => onChangeHandler('shippingAddress.state', option.value)}
          value={getSelectValue(address.state, stateOptions)}
          required
        />
        <Input
          id="zipcode-input"
          classNames={[styles.input]}
          errorText={errors['shippingAddress.zipcode']}
          label="ZIP"
          onTextChange={(text) => onChangeHandler('shippingAddress.zipcode', text)}
          value={address.zipcode}
          placeholder="Enter ZIP code"
          required
        />
      </div>
      {renderButtons({
        containerClassName: styles.buttonsContainer,
        buttonClassName: styles.button,
        onClose,
        onSubmit: onSubmitHandler,
        isSubmitEnabled: getIsButtonEnabled(address, errors),
      })}
    </BasePopup>
  );
};

PatientAddressPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default PatientAddressPopup;
