import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { AddressInput, CustomCheckbox, Input, Select } from 'common-src/components/base';
import Patient, {
  defaultAddressValues,
  updatePatient,
  validatePatient,
} from 'common-src/models/Patient';

import { Indicator } from 'src/components/elements';
import useAlert from 'src/hooks/useAlert';
import useFormButtons from 'src/hooks/useFormButtons';

import styles from './Addresses.module.scss';
import { stateOptions } from './constants';
import { catchErrors } from './helpers';

const Addresses = ({ patient }) => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({});

  const { showAlert, AlertType } = useAlert();

  const onSubmitHandler = (setIsLoading, setIsEditMode, handleErrors) => {
    const { errors, hasErrors } = catchErrors(formData);
    if (hasErrors) {
      handleErrors(errors);
      return;
    }

    setIsLoading(true);
    dispatch(
      updatePatient(
        patient.id,
        { ...formData },
        {
          successBlock: () => {
            setIsLoading(false);
            setIsEditMode(false);
            showAlert(AlertType.Success, '', 'You have successfully updated address information.');
          },
          errorBlock: (err) => {
            setIsLoading(false);
            showAlert(AlertType.Error, '', err);
          },
        },
      ),
    );
  };

  const {
    isEditMode,
    renderContent,
    setInitialData,
    setIsLoading,
    setIsEditMode,
    errors,
    handleErrors,
  } = useFormButtons(
    'section',
    setFormData,
    () => onSubmitHandler(setIsLoading, setIsEditMode, handleErrors),
    formData,
    true,
    'addresses',
  );

  useEffect(() => {
    const data = {
      address: {
        ...defaultAddressValues,
        ..._.mapValues(patient.address, (v) => (!v ? '' : v)),
      },
      shippingAddress: {
        ...defaultAddressValues,
        ..._.mapValues(patient.shippingAddress, (v) => (!v ? '' : v)),
      },
      shouldShipToHomeAddress: patient.shouldShipToHomeAddress,
    };
    setInitialData(data);
    setFormData(data);
  }, [JSON.stringify(_.pick(patient, ['address', 'shippingAddress', 'shouldShipToHomeAddress']))]);

  const handleInputChange = (field, value) => {
    handleErrors(validatePatient(field, value));
    setFormData((prev) => ({
      ...prev,
      ..._.set(prev, field, value === null ? null : value),
    }));
  };

  const renderAddressInputs = (key) => (
    <>
      <AddressInput
        key={`${key}-street1`}
        id={`${key}-street1`}
        errorText={errors[`${key}.street1`]}
        label="Address 1"
        placeholder="Enter street address"
        value={formData?.[key]?.street1}
        onChange={(addressInfo) => {
          Object.keys(addressInfo).map((field) =>
            handleInputChange(`${key}.${field}`, addressInfo[field] || ''),
          );
        }}
        onTextChange={(text) => {
          handleInputChange(`${key}.street1`, text);
        }}
        required
        readOnly={!isEditMode}
        size="small"
      />
      <Input
        key={`${key}-street2`}
        id={`${key}-street2`}
        errorText={errors[`${key}.street2`]}
        label="Address 2"
        onTextChange={(value) => handleInputChange(`${key}.street2`, value)}
        value={formData?.[key]?.street2}
        placeholder="Enter apt, unit, ste, etc."
        readOnly={!isEditMode}
        size="small"
      />
      <Input
        key={`${key}-city`}
        id={`${key}-city`}
        errorText={errors[`${key}.city`]}
        label="City"
        onTextChange={(value) => handleInputChange(`${key}.city`, value)}
        value={formData?.[key]?.city}
        placeholder="Enter city"
        required
        readOnly={!isEditMode}
        size="small"
      />
      <Select
        key={`${key}-state`}
        id={`${key}-state`}
        errorText={errors[`${key}.state`]}
        label="State"
        placeholder="Select state"
        options={stateOptions}
        onChange={(selection) => handleInputChange(`${key}.state`, selection.value)}
        value={stateOptions.find((op) => op.value === formData?.[key]?.state)}
        paperHeight={350}
        isSearchable
        required
        readOnly={!isEditMode}
        size="small"
        position="absolute"
      />
      <Input
        key={`${key}-zip-code`}
        id={`${key}-zip-code`}
        errorText={errors[`${key}.zipcode`]}
        label="Zip Code"
        onTextChange={(value) => handleInputChange(`${key}.zipcode`, value)}
        value={formData?.[key]?.zipcode}
        placeholder="Enter zip code"
        required
        readOnly={!isEditMode}
        size="small"
      />
    </>
  );

  const renderLegalAddress = () => (
    <>
      <span className={[styles.width100, 'font-s-12', 'font-w-600', 'p-b-10'].join(' ')}>
        Legal address
      </span>
      {renderAddressInputs('address')}
    </>
  );

  const renderShippingAddress = () => (
    <>
      <span className={[styles.width100, 'font-s-12', 'font-w-600', 'p-b-10'].join(' ')}>
        Shipping address (if different)
      </span>
      {renderAddressInputs('shippingAddress')}
    </>
  );

  const renderIndicator = () => {
    if (!isEditMode) {
      return formData?.shouldShipToHomeAddress ? <Indicator text="Shipping address" /> : null;
    }

    return (
      <CustomCheckbox
        id="ship-to-different-address-checkbox"
        header="Ship to different address"
        checked={!formData?.shouldShipToHomeAddress}
        onChange={(value) => handleInputChange('shouldShipToHomeAddress', !value)}
        disabled={!isEditMode}
        classNames={[styles.checkbox, isEditMode ? styles.edit : '']}
      />
    );
  };

  const renderForm = () => (
    <div className={styles.grid}>
      {renderLegalAddress()}
      {renderIndicator()}
      {!formData?.shouldShipToHomeAddress ? renderShippingAddress() : null}
    </div>
  );

  return (
    <>
      {renderForm()}
      {renderContent()}
    </>
  );
};

Addresses.propTypes = {
  patient: PropTypes.exact(Patient.schema),
};

export default Addresses;
