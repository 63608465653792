import { rolePermissions } from 'common-src/features/auth/constants';
import { getCurrentUserId } from 'common-src/features/auth/selectors';
import { allModelsSelector, singleModelSelector } from 'common-src/utils/selectorUtils';

import { sortByName } from './helpers';

export const getClients = (state) => allModelsSelector(state, 'Client');

export const getClient = (state, id) => singleModelSelector(state, 'Client', id);

export const getCurrentClient = (state) =>
  singleModelSelector(state, 'Client', getCurrentUserId(state));

export const getAccountabilityCoaches = (state) => {
  const clients = getClients(state);

  return clients
    .filter((client) => {
      if (client.offboardedAt) return false;

      const clientPermissions = rolePermissions[client.roleId];
      return clientPermissions.patientChart.sections?.physicianOrders?.assignedAsAC;
    })
    .sort(sortByName);
};

export const getRegisteredDietitians = (state) => {
  const clients = getClients(state);

  return clients
    .filter((client) => {
      if (client.offboardedAt) return false;

      const clientPermissions = rolePermissions[client.roleId];
      return clientPermissions.patientChart.sections?.physicianOrders?.assignedAsRD;
    })
    .sort(sortByName);
};

export const getCDCESs = (state) => {
  const clients = getClients(state);

  return clients
    .filter((client) => {
      if (client.offboardedAt) return false;

      const clientPermissions = rolePermissions[client.roleId];
      return clientPermissions.patientChart.sections?.physicianOrders?.assignedAsCDCES;
    })
    .sort(sortByName);
};

export const getSocialWorkers = (state) => {
  const clients = getClients(state);

  return clients
    .filter((client) => {
      if (client.offboardedAt) return false;

      const clientPermissions = rolePermissions[client.roleId];
      return clientPermissions.patientChart.sections?.physicianOrders?.assignedAsLcsw;
    })
    .sort(sortByName);
};
