import { getCurrentUserId } from 'common-src/features/auth';
import { allModelsSelector, singleModelSelector } from 'common-src/utils/selectorUtils';

export const getDocumentationById = (state, patientInteractionId) =>
  singleModelSelector(state, 'PatientInteraction', patientInteractionId);

export const getPatientDrafts = (state, patientId) => {
  const currentUserId = getCurrentUserId(state);
  return allModelsSelector(
    state,
    'PatientInteraction',
    (interaction) =>
      (interaction.updatedBy === currentUserId || interaction.client === currentUserId) &&
      interaction.patientId === patientId &&
      interaction.isDraft,
  );
};

export const getPatientInteractionAddendums = (state, docId) =>
  allModelsSelector(
    state,
    'PatientInteraction',
    (interaction) => interaction.parentInteractionId === docId && !interaction.isDraft,
  ).sort(
    (a, b) =>
      new Date(b.serviceAt) - new Date(a.serviceAt) ||
      new Date(b.updatedAt) - new Date(a.updatedAt),
  );
