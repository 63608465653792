import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Select } from 'common-src/components/base';
import {
  ActivePatientTaskStatuses,
  getStatusById,
  getTaskReasonById,
  getTaskTypeById,
  PatientTaskStatus,
} from 'common-src/models/PatientTask';
import { updatePatientTask } from 'common-src/models/PatientTask/actions';

import { InfoItem } from 'src/components/elements';
import usePopup from 'src/hooks/usePopup';
import BasePopup from 'src/popups/BasePopup';

import styles from './EditTaskPopup.module.scss';
import { getIsButtonEnabled } from './helpers';

const EditTaskPopup = ({ onClose, task, isReadOnly }) => {
  const dispatch = useDispatch();

  const [data, setData] = useState({});

  const { renderButtons } = usePopup();

  useEffect(() => {
    if (!task) return;
    setData({
      status: Object.values(PatientTaskStatus).find((status) => status.value === task.status),
    });
  }, [task]);

  const onSubmitHandler = (loadingCallback, successCallback, errorCallback) => {
    loadingCallback();

    dispatch(
      updatePatientTask(
        task.id,
        { status: data.status.value },
        {
          successBlock: () => {
            successCallback('Task edited!');
            onClose(true);
          },
          errorBlock: (err) => errorCallback(err),
        },
      ),
    );
  };

  const renderStatus = () => {
    if (isReadOnly) {
      return (
        <InfoItem
          textId="status"
          title="Status"
          content={getStatusById(task.status)?.label}
          small
        />
      );
    }

    return (
      <Select
        id="status"
        label="Status"
        options={Object.values(PatientTaskStatus)}
        onChange={(status) => setData({ status })}
        value={data.status}
        required
        placeholder="Select status"
        size="small"
        disabled={!ActivePatientTaskStatuses.includes(task.status)}
      />
    );
  };

  return (
    <BasePopup
      id="edit-task"
      open
      onClose={onClose}
      title={isReadOnly ? 'Task Details' : 'Edit Task'}
      customStyle={{ width: '55vw' }}
    >
      <div className={styles.grid}>
        <InfoItem
          classNames={[styles.width100]}
          textId="type"
          title="Type"
          content={getTaskTypeById(task?.typeId)?.label}
          small
        />
        <InfoItem
          textId="created-at"
          title="Created"
          content={moment(task.createdAt).format('MM/DD/YYYY')}
          small
        />
        <InfoItem
          textId="created-by"
          title="Created By"
          content={task.createdBy?.getName(true)}
          small
        />
        {renderStatus()}
        <InfoItem
          classNames={[styles.width100]}
          textId="reason"
          title="Reason"
          content={getTaskReasonById(task?.reasonId)?.label}
          small
        />
        <InfoItem
          classNames={[styles.width100]}
          textId="subject"
          title="Subject"
          content={task.subject}
          small
          multiline
        />
      </div>
      {!isReadOnly &&
        renderButtons({
          containerClassName: styles.buttonsContainer,
          onClose,
          onSubmit: onSubmitHandler,
          isSubmitEnabled: getIsButtonEnabled(data, task),
          submitButtonText: 'Save and Close',
        })}
    </BasePopup>
  );
};

EditTaskPopup.propTypes = {
  onClose: PropTypes.func,
  task: PropTypes.object,
  isReadOnly: PropTypes.bool,
};

export default EditTaskPopup;
