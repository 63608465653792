import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { Colors, ColorsNew } from 'common-src/styles';

import CircleLoader from '../CircleLoader';
import Tooltip from '../Tooltip';
import styles from './Button.module.scss';

const Button = ({
  id,
  text,
  iconSrc,
  rightIconSrc,
  tooltipText,
  width = 0,
  classNames = [],
  disabled = false,
  isLoading = false,
  onClick = () => {},
  textColor = Colors.white,
  borderColor = ColorsNew.darkGreen,
  backgroundColor = ColorsNew.darkGreen,
}) => {
  const ref = useRef();
  const [initialWidth, setInitialWidth] = useState(0);
  const [initialHeight, setInitialHeight] = useState(0);

  const buttonClasses = [
    styles.button,
    'gap-8',
    'vertically-centered',
    'horizontally-centered',
    'font-w-500',
    'border-r-6',
    'font-s-16',
    ...classNames,
  ];
  if (isLoading) {
    buttonClasses.push(styles.loading);
  }

  useEffect(() => {
    if (!ref.current) return;
    setInitialWidth(ref.current.scrollWidth + 3);
    setInitialHeight(ref.current.clientHeight);
  }, [ref.current]);

  const onClickHandler = (e) => {
    if (disabled || isLoading) return;
    onClick(e);
  };

  const renderContent = () => {
    if (isLoading) {
      return <CircleLoader strokeWidth={3} circleRadius={initialHeight / 4} />;
    }

    return (
      <>
        {iconSrc && <img src={iconSrc} alt="button-icon" />}
        {text && <span className={styles.text}>{text}</span>}
        {rightIconSrc && <img src={rightIconSrc} alt="right-button-icon" />}
      </>
    );
  };

  const renderButton = () => (
    <button
      id={id}
      className={buttonClasses.join(' ')}
      style={{
        borderColor,
        backgroundColor,
        color: textColor,
        minWidth: `${Math.max(width, initialWidth)}px`,
      }}
      onClick={onClickHandler}
      disabled={disabled}
      type="button"
      ref={ref}
    >
      {renderContent()}
    </button>
  );

  if (tooltipText) {
    return <Tooltip title={tooltipText}>{renderButton()}</Tooltip>;
  }

  return <>{renderButton()}</>;
};

Button.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  textColor: PropTypes.string,
  classNames: PropTypes.arrayOf(PropTypes.string),
  borderColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  iconSrc: PropTypes.string,
  width: PropTypes.number,
  rightIconSrc: PropTypes.string,
  tooltipText: PropTypes.string,
};

export default Button;
