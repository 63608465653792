import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

import { Autocomplete, DatePickerNew, Input, Select } from 'common-src/components/base';
import { apiRequest } from 'common-src/features/rest';
import {
  PayorType,
  PayorTypeOptions,
  PlanSource,
  PlanStatus,
  SubscriberRelationship,
  validatePatientCoverage,
} from 'common-src/models/PatientCoverage';

import { InfoItem } from 'src/components/elements';

import styles from '../commonStyles.module.scss';

const Commercial = ({
  data,
  handleInputChange,
  handleMultipleValuesChange,
  handleDateChange,
  isChangeStatusAllowed,
  errors,
  setErrors,
  shouldReset,
}) => {
  const renderSubscriberForm = () => {
    if (data.subscriberRelationship === SubscriberRelationship.Self.value) {
      return null;
    }

    return (
      <>
        <Input
          id="subscriber-first-name"
          placeholder="Enter first name"
          label="Subscriber First Name"
          value={data.subscriberFirstName}
          onTextChange={(value) => handleInputChange('subscriberFirstName', value)}
          errorText={errors.subscriberFirstName}
          required
        />
        <Input
          id="subscriber-last-name"
          placeholder="Enter last name"
          label="Subscriber Last Name"
          value={data.subscriberLastName}
          onTextChange={(value) => handleInputChange('subscriberLastName', value)}
          errorText={errors.subscriberLastName}
          required
        />
        <DatePickerNew
          id="subscriber-dob"
          header="Subscriber Date of Birth"
          placeholder="Select date"
          maxDate={new Date()}
          value={data.subscriberDateOfBirth}
          onDateSelected={(date) =>
            handleInputChange(
              'subscriberDateOfBirth',
              date ? moment(date).format('YYYY-MM-DD') : null,
            )
          }
          onBlur={(date) =>
            handleInputChange(
              'subscriberDateOfBirth',
              date ? moment(date).format('YYYY-MM-DD') : null,
            )
          }
          errorText={errors.subscriberDateOfBirth}
          required
        />
      </>
    );
  };

  const onChangeSubscriberRelationship = (op) => {
    if (op.value === SubscriberRelationship.Self.value) {
      handleMultipleValuesChange({
        subscriberRelationship: op.value,
        subscriberFirstName: undefined,
        subscriberLastName: undefined,
        subscriberDateOfBirth: null,
      });

      setErrors((prev) => ({
        ...prev,
        subscriberFirstName: '',
        subscriberLastName: '',
        subscriberDateOfBirth: '',
      }));

      return;
    }

    handleInputChange('subscriberRelationship', op.value);
  };

  return (
    <>
      <Select
        id="insurance-type"
        label="Please select insurance type"
        options={PayorTypeOptions}
        onChange={(op) => handleInputChange('payorType', op.value)}
        value={Object.values(PayorType).find((op) => op.value === data.payorType)}
        required
        placeholder="-"
      />
      <Select
        id="subscriber-relationship"
        label="Subscriber Relationship"
        options={Object.values(SubscriberRelationship)}
        onChange={(op) => onChangeSubscriberRelationship(op)}
        value={Object.values(SubscriberRelationship).find(
          (op) => op.value === data.subscriberRelationship,
        )}
        required
      />
      <span />
      <Autocomplete
        id="search-payor"
        classNames={[styles.autocomplete, 'm-b-16']}
        label="Payor Search"
        shouldReset={shouldReset}
        initialOptionsCount={50}
        renderOption={(option) => (
          <span className={[styles.option, 'font-w-500', 'font-s-14'].join(' ')}>
            {`${option.id} (${option.payorName})`}
          </span>
        )}
        onOptionSelect={(option) =>
          handleMultipleValuesChange({ payorName: option.payorName, payorId: option.id })
        }
        getRequest={(searchText, count) =>
          apiRequest({
            endpoint: 'payorDetails',
            queryParams: { input: searchText, limit: count },
          })
        }
        paperStyles={{ left: 0, width: 'max-content' }}
      />
      <span />
      <span />
      <InfoItem
        classNames={[styles.width75]}
        title="Payor Name"
        content={data.payorName}
        textId="payor-name"
      />
      <InfoItem title="Payor ID*" content={data.payorId} textId="payor-id" />
      <Input
        id="member-id"
        placeholder="Enter member id"
        label="Member ID"
        value={data.memberId}
        onTextChange={(value) => handleInputChange('memberId', value)}
        errorText={errors.memberId}
      />
      <Select
        id="status"
        label="Status"
        options={Object.values(PlanStatus)}
        onChange={(op) => handleInputChange('status', op.value)}
        value={Object.values(PlanStatus).find((op) => op.value === data.status)}
        disabled={!isChangeStatusAllowed}
        required
      />
      <InfoItem
        title="Source"
        content={Object.values(PlanSource).find((op) => op.value === data.source)?.label}
        textId="source"
      />
      <DatePickerNew
        id="start-date"
        header="Start date"
        placeholder="Select date"
        value={data.startDate}
        onDateSelected={(date) =>
          handleDateChange(
            'startDate',
            'endDate',
            true,
            date ? moment(date).format('YYYY-MM-DD') : null,
          )
        }
        onBlur={(date) =>
          setErrors((prev) => ({ ...prev, ...validatePatientCoverage('startDate', date, data) }))
        }
        errorText={errors.startDate}
        required
      />
      <DatePickerNew
        id="end-date"
        header="End date"
        placeholder="Select date"
        value={data.endDate}
        onDateSelected={(date) =>
          handleDateChange(
            'endDate',
            'startDate',
            false,
            date ? moment(date).format('YYYY-MM-DD') : null,
          )
        }
        onBlur={(date) =>
          setErrors((prev) => ({ ...prev, ...validatePatientCoverage('endDate', date, data) }))
        }
        errorText={errors.endDate}
      />
      <Input
        id="plan-name"
        classNames={[styles.width75]}
        placeholder="Enter plan name"
        label="Plan Name"
        value={data.planName}
        onTextChange={(value) => handleInputChange('planName', value)}
      />
      <span />
      {renderSubscriberForm()}
    </>
  );
};

Commercial.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  setErrors: PropTypes.func,
  shouldReset: PropTypes.bool,
  handleDateChange: PropTypes.func,
  handleInputChange: PropTypes.func,
  isChangeStatusAllowed: PropTypes.bool,
  handleMultipleValuesChange: PropTypes.func,
};

export default Commercial;
