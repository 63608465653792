import _ from 'lodash';
import moment from 'moment-timezone';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { isFeatureEnabled } from 'common-src/models/FeatureFlag';
import {
  finalizePatientInteraction,
  updatePatientDraftApiRequest,
} from 'common-src/models/PatientInteraction';
import { getLastA1cReading, getPatientLabResults } from 'common-src/models/PatientLabReading';

import { ModalType } from 'src/components/base/ModalGroup';
import { openModal } from 'src/features/modals';
import {
  saveNoteData,
  savePatientDocumentationTabInfo,
  savePatientNote,
} from 'src/features/tabsState';
import { getTimer } from 'src/features/timeTracker';
import useAlert from 'src/hooks/useAlert';
import useCustomSelector from 'src/hooks/useCustomSelector';
import {
  checkModuleRequiredFields,
  getDocumentFormErrors,
  getFinalizedDocs,
} from 'src/pages/DocumentationIndex/helpers';

import useAutoSave from './useAutoSave';

const AUTO_SAVE_TIMEOUT_LENGTH = 5000;

const useSaveDocumentation = ({
  withBilling = true,
  handleError,
  modules,
  documentationToEdit,
  patientId,
  primaryModuleTypeId,
  serviceAt,
  showBillingInfo,
  setShowBillingInfo,
  timeLogs = [],
  regalTaskIds = [],
  awscCallIds = [],
  hasRelevantConversations = false,
  isEncounterNotScheduled = false,
  onClose,
  isEmbedded,
  linkedVisitId,
  sdoh = {},
  vitalsAndLabs = {},
  wellnessPlan = {},
  cgmAlerts = {},
  cgmEvaluation = {},
}) => {
  const dispatch = useDispatch();

  const [isServiceAtValid, setIsServiceAtValid] = useState(true);
  const [isDraftCompleting, setIsDraftCompleting] = useState(false);
  const [isDraftSaving, setIsDraftSaving] = useState(false);
  const [hasDetectedChanges, setHasDetectedChanges] = useState(null);

  const prevNoteData = useRef({});

  const timer = useCustomSelector((state) => getTimer(state, patientId));
  const labResults = useCustomSelector((state) => getPatientLabResults(state, patientId));
  const lastA1c = useCustomSelector((state) => getLastA1cReading(state, patientId));
  const isCGMAssessmentEnabled = useCustomSelector((state) =>
    isFeatureEnabled(state, 'cgmAssessment'),
  );

  const { showAlert, AlertType } = useAlert();

  const isAddendum = !!documentationToEdit.parentInteractionId;

  useEffect(() => {
    if (!documentationToEdit?.id || !patientId) return;

    const noteData = {
      moduleTypes: documentationToEdit.moduleTypes,
      draftData: documentationToEdit.draftData,
      serviceAt: documentationToEdit.serviceAt,
      primaryModuleTypeId: documentationToEdit.primaryModuleTypeId,
    };

    dispatch(savePatientNote(patientId, documentationToEdit.id));
    dispatch(saveNoteData(patientId, noteData));
    prevNoteData.current = noteData;
  }, [documentationToEdit?.id, patientId]);

  useEffect(() => {
    if (!hasDetectedChanges) return;

    const selectedModules = modules.filter((m) => m.isSelected);
    const noteData = {
      moduleTypes: selectedModules.map((m) => m.moduleType),
      draftData: {
        docs: selectedModules,
        timeLogs,
        sdoh,
        vitalsAndLabs,
        wellnessPlan,
        cgmAlerts,
        cgmEvaluation,
      },
      serviceAt: moment(serviceAt).utc().toISOString(),
      primaryModuleTypeId: primaryModuleTypeId || null,
    };

    dispatch(saveNoteData(patientId, noteData));
    prevNoteData.current = noteData;
  }, [hasDetectedChanges]);

  const saveDocumentation = () => {
    if (isDraftSaving || isDraftCompleting || !documentationToEdit.isDraft) return;

    const selectedModules = modules.filter((m) => m.isSelected);
    const { validationError } = checkModuleRequiredFields(selectedModules);
    if (validationError) {
      handleError({ generalError: validationError, fieldErrors: new Map() });
      return;
    }

    setIsDraftSaving(true);
    dispatch(
      updatePatientDraftApiRequest(
        documentationToEdit.id,
        {
          moduleTypes: selectedModules.map((m) => m.moduleType),
          draftData: {
            docs: selectedModules,
            timeLogs,
            sdoh,
            vitalsAndLabs,
            wellnessPlan,
            cgmAlerts,
            cgmEvaluation,
          },
          serviceAt: moment(serviceAt).utc().toISOString(),
          primaryModuleTypeId,
          duration: timer,
        },
        {
          successBlock: () => setIsDraftSaving(false),
          errorBlock: (error) => {
            setIsDraftSaving(false);
            handleError({ generalError: error, fieldErrors: new Map() });
          },
        },
      ),
    );
  };

  const { savedAt } = useAutoSave(AUTO_SAVE_TIMEOUT_LENGTH, hasDetectedChanges, () =>
    saveDocumentation(),
  );

  const completeDocumentation = (hadLiveInteraction, timer) => {
    setIsDraftCompleting(true);
    dispatch(
      finalizePatientInteraction(
        documentationToEdit.id,
        {
          serviceAt: moment(serviceAt).utc().toISOString(),
          primaryModuleTypeId,
          draftData: {
            docs: getFinalizedDocs(modules),
            timeLogs,
            sdoh,
            vitalsAndLabs,
            wellnessPlan,
            cgmAlerts,
            cgmEvaluation,
          },
          populateSdoh: {
            issues: _.get(sdoh, 'sections[0].contents[0].value', []),
            interestedInDiscussing: _.get(sdoh, 'sections[0].contents[1].value', null),
            additionalDetails: _.get(sdoh, 'sections[0].contents[2].value', null),
          },
          populateVitalsAndLabs: !_.isEmpty(vitalsAndLabs)
            ? {
                ...vitalsAndLabs,
                weightDateObserved: vitalsAndLabs.weightDateObserved
                  ? moment(vitalsAndLabs.weightDateObserved).format('YYYY-MM-DD')
                  : null,
                hbA1cDateObserved: vitalsAndLabs.hbA1cDateObserved
                  ? moment(vitalsAndLabs.hbA1cDateObserved).format('YYYY-MM-DD')
                  : null,
              }
            : null,
          populateWellnessPlan: wellnessPlan,
          populateCgmAlerts: isCGMAssessmentEnabled && !_.isEmpty(cgmAlerts) ? cgmAlerts : null,
          populateCgmEvaluation:
            isCGMAssessmentEnabled && !_.isEmpty(cgmEvaluation) ? cgmEvaluation : null,
          regalTaskIds,
          awscCallIds,
          manualTime: timeLogs.map((tl) => ({ ...tl, timeSpent: Number(tl.timeSpent) * 60 })),
          missingRegalEvents: hasRelevantConversations,
          hadLiveInteraction,
          duration: timer,
          oncehubBookingId: linkedVisitId,
        },
        {
          successBlock: () => {
            setIsDraftCompleting(false);
            dispatch(savePatientDocumentationTabInfo(patientId, {}));
            showAlert(AlertType.Success, '', 'Success. Documentation Saved!');
            setTimeout(() => {
              if (onClose) {
                onClose();
              } else {
                window.close();
              }
            }, 500);
          },
          errorBlock: (error) => {
            setIsDraftCompleting(false);
            handleError({ generalError: error, fieldErrors: new Map() });
          },
        },
      ),
    );
  };

  const handleButtonClick = () => {
    if (isDraftCompleting) return;

    const { generalError, fieldErrors } = getDocumentFormErrors(
      modules,
      documentationToEdit,
      serviceAt,
      isServiceAtValid,
      showBillingInfo,
      timeLogs,
      regalTaskIds,
      awscCallIds,
      !isAddendum && !hasRelevantConversations,
      isEmbedded,
      !isEncounterNotScheduled && !linkedVisitId,
      sdoh,
      vitalsAndLabs,
      labResults,
      lastA1c,
      cgmEvaluation,
    );

    if (generalError || !_.isEmpty(fieldErrors)) {
      const questionId = fieldErrors.entries().next().value?.[0];
      const element = document.getElementById(questionId);
      setTimeout(() => {
        if (element) element.scrollIntoView({ behavior: 'smooth' });
      }, 0);

      handleError({ generalError, fieldErrors });
      return;
    }

    handleError({ generalError: null, fieldErrors: new Map() });

    if (!showBillingInfo && withBilling && !isEmbedded) {
      setShowBillingInfo(true);
      return;
    }

    if (isEmbedded) {
      dispatch(
        openModal(ModalType.NOTE_CONFIRMATION, {
          onSubmit: (hadLiveInteraction) => completeDocumentation(hadLiveInteraction, timer),
        }),
      );

      return;
    }

    completeDocumentation();
  };

  const handleBackButtonClick = () => setShowBillingInfo(false);

  return {
    timer,
    savedAt: savedAt || documentationToEdit.updatedAt,
    isDraftCompleting,
    isDraftSaving,
    hasDetectedChanges,
    setIsServiceAtValid,
    handleButtonClick,
    handleBackButtonClick,
    setHasDetectedChanges,
  };
};

export default useSaveDocumentation;
