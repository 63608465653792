import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { Checkbox, DatePickerNew, Input, Select } from 'common-src/components/base';
import {
  PayorType,
  PayorTypeOptions,
  PlanSource,
  PlanStatus,
  validatePatientCoverage,
} from 'common-src/models/PatientCoverage';

import { InfoItem } from 'src/components/elements';

import styles from '../commonStyles.module.scss';

const Medicare = ({
  data,
  handleInputChange,
  handleDateChange,
  isChangeStatusAllowed,
  errors,
  setErrors,
}) => {
  useEffect(() => {
    if (!data.memberId) return;

    setErrors((prev) => ({ ...prev, ...validatePatientCoverage('memberId', data.memberId, data) }));
  }, [data.partA, data.partB]);

  return (
    <>
      <Select
        id="insurance-type"
        label="Please select insurance type"
        options={PayorTypeOptions}
        onChange={(op) => handleInputChange('payorType', op.value)}
        value={Object.values(PayorType).find((op) => op.value === data.payorType)}
        required
        placeholder="-"
      />
      <span />
      <span />
      <InfoItem
        classNames={[styles.width75]}
        title="Payor Name"
        content={data.payorName}
        textId="payor-name"
      />
      <InfoItem title="Payor ID" content={data.payorId} textId="payor-id" />
      <Input
        id="member-id"
        placeholder="Enter member id"
        label="Member ID"
        value={data.memberId}
        onTextChange={(value) => handleInputChange('memberId', value)}
        errorText={errors.memberId}
      />
      <Select
        id="status"
        label="Status"
        options={Object.values(PlanStatus)}
        onChange={(op) => handleInputChange('status', op.value)}
        value={Object.values(PlanStatus).find((op) => op.value === data.status)}
        disabled={!isChangeStatusAllowed}
        required
      />
      <InfoItem
        title="Source"
        content={Object.values(PlanSource).find((op) => op.value === data.source)?.label}
        textId="source"
      />
      <Checkbox
        id="medicare-part-a"
        classNames={['m-b-10']}
        checked={data.partA}
        onChange={(checked) => handleInputChange('partA', checked)}
        label="Medicare Part A"
      />
      <Checkbox
        id="medicare-part-b"
        classNames={['m-b-10']}
        checked={data.partB}
        onChange={(checked) => handleInputChange('partB', checked)}
        label="Medicare Part B"
      />
      <span />
      <DatePickerNew
        id="start-date"
        header="Start date"
        placeholder="Select date"
        value={data.startDate}
        onDateSelected={(date) =>
          handleDateChange(
            'startDate',
            'endDate',
            true,
            date ? moment(date).format('YYYY-MM-DD') : null,
          )
        }
        onBlur={(date) =>
          setErrors((prev) => ({ ...prev, ...validatePatientCoverage('startDate', date, data) }))
        }
        errorText={errors.startDate}
        required
      />
      <DatePickerNew
        id="end-date"
        header="End date"
        placeholder="Select date"
        value={data.endDate}
        onDateSelected={(date) =>
          handleDateChange(
            'endDate',
            'startDate',
            false,
            date ? moment(date).format('YYYY-MM-DD') : null,
          )
        }
        onBlur={(date) =>
          setErrors((prev) => ({ ...prev, ...validatePatientCoverage('endDate', date, data) }))
        }
        errorText={errors.endDate}
      />
    </>
  );
};

Medicare.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  setErrors: PropTypes.func,
  handleDateChange: PropTypes.func,
  handleInputChange: PropTypes.func,
  isChangeStatusAllowed: PropTypes.bool,
};

export default Medicare;
