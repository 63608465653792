import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { CommonIcons } from 'common-src/assets/Icons';
import { Tooltip } from 'common-src/components/base';

import styles from './InfoItem.module.scss';

const InfoItem = ({
  textId,
  title,
  content,
  suffix,
  tooltipText,
  small = false,
  isSmall = false,
  classNames = [],
  multiline = false,
}) => {
  const ref = useRef();
  const textRef = useRef();

  const [maxWidth, setMaxWidth] = useState(0);
  const [isOverflowed, setIsOverflow] = useState(false);

  useEffect(() => {
    if (!ref.current) return;
    setMaxWidth(ref.current.scrollWidth);
  }, [ref.current]);

  useEffect(() => {
    if (!textRef.current) return;
    setIsOverflow(textRef.current.scrollWidth > textRef.current.clientWidth);
  }, [textRef.current, content]);

  const renderTitle = () => (
    <div className="flex-row gap-6 vertically-centered">
      <span
        className={[styles.title, 'font-w-500'].join(' ')}
        style={{ fontSize: isSmall ? '12px' : small ? '10px' : '14px' }}
      >
        {title}
      </span>
      {tooltipText && (
        <Tooltip title={tooltipText}>
          <img className={styles.tooltipIcon} src={CommonIcons.tooltipIcon} alt="tooltip-icon" />
        </Tooltip>
      )}
    </div>
  );

  return (
    <div ref={ref} className={['flex-column', 'gap-6', ...classNames].join(' ')}>
      {renderTitle()}
      <Tooltip title={content || '-'} disableHoverListener={!isOverflowed}>
        <span
          ref={textRef}
          id={textId}
          className={[styles.content, 'font-w-500'].join(' ')}
          style={{
            maxWidth: `${maxWidth}px`,
            fontSize: isSmall || small ? '14px' : '18px',
            whiteSpace: multiline ? 'normal' : 'pre',
          }}
        >
          {content || '-'}
        </span>
      </Tooltip>
      {suffix && (
        <span className={[styles.suffix, 'font-w-400', 'font-s-12'].join(' ')}>{suffix}</span>
      )}
    </div>
  );
};

InfoItem.propTypes = {
  textId: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  suffix: PropTypes.string,
  classNames: PropTypes.arrayOf(PropTypes.string),
  small: PropTypes.bool,
  multiline: PropTypes.bool,
  tooltipText: PropTypes.string,
  isSmall: PropTypes.bool,
};

export default InfoItem;
