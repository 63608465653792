export const ModalType = Object.freeze({
  ALLERGY: 'Allergy',
  ALLERGY_DETAILS: 'AllergyDetails',
  MAX_TAB_LIMIT: 'MaxTabLimit',
  ADD_CLIENT: 'AddClient',
  MEDICATION: 'Medication',
  MEDICATION_DETAILS: 'MedicationDetails',
  CGM_ORDER: 'CgmOrder',
  CGM_AUTH: 'CgmAuth',
  ENCOUNTER: 'Encounter',
  ENCOUNTER_DETAILS: 'EncounterDetails',
  ADD_ADDENDUM: 'AddAddendum',
  SURGICAL_HISTORY: 'SurgicalHistory',
  SURGICAL_HISTORY_DETAILS: 'SurgicalHistoryDetails',
  SMART_GOAL: 'SmartGoal',
  SUPPLEMENT: 'Supplement',
  SUPPLEMENT_DETAILS: 'SupplementDetails',
  FAMILY_HISTORY: 'FamilyHistory',
  WARNING: 'Warning',
  ORDER_DETAILS: 'OrderDetails',
  PATIENT_DEVICES: 'PatientDevices',
  LAB_RESULT: 'LabResult',
  TRENDED_RESULTS: 'TrendedResults',
  RESULTS_DETAILS: 'ResultsDetails',
  REMOVE_LAB_RESULT: 'RemoveLabResult',
  PROBLEM_DETAILS: 'ProblemDetails',
  MEDICAL_PROBLEM: 'MedicalProblem',
  NOTICE: 'Notice',
  CONFIRM_REASSIGNMENT: 'ConfirmReassignment',
  ADD_DOCUMENTATION: 'AddDocumentation',
  SEND_REPORT: 'SendReport',
  SEND_DOCUMENTATION: 'SendDocumentation',
  APP_UPDATE: 'AppUpdate',
  EDIT_STRIP_COUNT: 'EditStripCount',
  PATIENT_DEVICE: 'PatientDevice',
  PATIENT_ADDRESS: 'PatientAddress',
  ORDER_STRIPS: 'OrderStrips',
  MANUAL_CGM_ORDER: 'ManualCGMOrder',
  ADD_PLAN: 'AddPlan',
  EDIT_PLAN: 'EditPlan',
  EXEMPTION: 'Exemption',
  PRE_AUTH: 'PreAuth',
  NOTE_CONFIRMATION: 'NoteConfirmation',
  VITALS: 'Vitals',
  LAB: 'Lab',
  DATE_OF_SERVICE: 'DateOfService',
  ADD_NOTE: 'AddNote',
  SDOH: 'Sdoh',
  EDIT_TASK: 'EditTask',
  ADD_TASK: 'AddTask',
  REQUEST_ADJUSTMENTS: 'RequestAdjustments',
  INTERACTION_ADJUSTMENTS: 'InteractionAdjustments',
});

export const withPatientStatusCheck = [
  ModalType.ALLERGY,
  ModalType.MEDICATION,
  ModalType.MEDICATION_DETAILS,
  ModalType.ENCOUNTER,
  ModalType.ADD_ADDENDUM,
  ModalType.SURGICAL_HISTORY,
  ModalType.SMART_GOAL,
  ModalType.SUPPLEMENT,
  ModalType.FAMILY_HISTORY,
  ModalType.REMOVE_LAB_RESULT,
  ModalType.PROBLEM_DETAILS,
  ModalType.MEDICAL_PROBLEM,
  ModalType.CGM_ORDER,
  ModalType.NOTICE,
  ModalType.ADD_DOCUMENTATION,
  ModalType.SEND_DOCUMENTATION,
  ModalType.EDIT_STRIP_COUNT,
  ModalType.CGM_AUTH,
  ModalType.PATIENT_DEVICE,
  ModalType.ORDER_STRIPS,
  ModalType.MANUAL_CGM_ORDER,
  ModalType.ADD_PLAN,
  ModalType.EDIT_PLAN,
  ModalType.VITALS,
  ModalType.LAB,
  ModalType.ADD_NOTE,
  ModalType.SDOH,
  ModalType.EDIT_TASK,
  ModalType.ADD_TASK,
  ModalType.REQUEST_ADJUSTMENTS,
  ModalType.INTERACTION_ADJUSTMENTS,
];

export const withDocumentationWarning = [ModalType.ADD_DOCUMENTATION];

export const CLOSE_MODAL_DELAY = 500;
export const CLOSE_MODAL_DELAY_AFTER_SUCCESS = 2000;
