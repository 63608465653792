import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { CircleLoader, List } from 'common-src/components/base';
import { apiRequest } from 'common-src/features/rest';
import { getClients } from 'common-src/models/Client';
import PatientInteraction, { DocumentationSort } from 'common-src/models/PatientInteraction';
import { ColorsNew } from 'common-src/styles';

import { SeparatorLine } from 'src/components/elements';
import useCustomSelector from 'src/hooks/useCustomSelector';
import useInterval from 'src/hooks/useInterval';
import usePagination from 'src/hooks/usePagination';

import { DOCUMENTATION_POLLING_FREQUENCY, REQUEST_PAGE_SIZE } from '../../constants';
import { DocumentationContent, DocumentationItem } from '../../items';
import styles from './DocumentationTab.module.scss';

const DocumentationTab = ({
  patientId,
  filters,
  selectedDocument,
  updatePrevTabInfo,
  setSelectedDocument,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [pageSize, setPageSize] = useState(REQUEST_PAGE_SIZE);

  const scrollRef = useRef(null);
  const isEndReached = useRef(false);

  const clients = useCustomSelector((state) => getClients(state));

  const parentDocuments = useMemo(
    () => documents?.filter((d) => !d.parentInteractionId),
    [documents],
  );
  const addendums = useMemo(() => documents?.filter((d) => !!d.parentInteractionId), [documents]);
  const sortOrder = useMemo(
    () => (filters?.sort?.value === 'Oldest first' ? 'asc' : 'desc'),
    [filters],
  );

  const sendPatientInteractionsRequest = (
    patientId,
    skip,
    limit,
    filters,
    showLoader,
    successBlock,
  ) => {
    if (isLoading || _.isEmpty(filters)) return;

    const startingDate = filters.startingDate === null ? undefined : filters.startingDate;
    const endingDate = filters.endingDate === null ? undefined : filters.endingDate;
    const moduleType = filters.type.value < 0 ? undefined : filters.type.value;
    const sortOrder = filters.sort.value === DocumentationSort.NEWEST_FIRST ? 'desc' : 'asc';
    const hadLiveInteraction =
      filters.hadLiveInteraction.value === null ? undefined : filters.hadLiveInteraction.value;
    const search = _.isEmpty(filters.search) ? undefined : filters.search;

    /**
     * Increase page size to return more doc results if there are many addendums returned on the initial call.
     * @param skip
     * @param returnedPatientInteractions
     */
    const handleAddendums = (skip, returnedPatientInteractions) => {
      // in case of page different from the first one - return
      if (skip !== 0 || returnedPatientInteractions.length < pageSize) return;

      const nonAddendums = returnedPatientInteractions.filter((d) => !d.parentInteractionId);
      // send new request with increased pageSize and change the state
      if (nonAddendums.length < REQUEST_PAGE_SIZE / 2) {
        sendPatientInteractionsRequest(patientId, 0, pageSize * 2, filters, true, setDocuments);
        setPageSize(pageSize * 2);
      }
    };

    setIsLoading(showLoader);
    apiRequest({
      endpoint: 'patientInteractions',
      queryParams: {
        patientId,
        isDraft: false,
        skip,
        limit,
        startingDate,
        endingDate,
        moduleType,
        sortOrder,
        hadLiveInteraction,
        search,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        const returnedPatientInteractions = data.PatientInteraction || [];

        handleAddendums(skip, returnedPatientInteractions);

        successBlock?.(returnedPatientInteractions);
      })
      .finally(() => setIsLoading(false));
  };

  const getClient = (id) => clients?.find((c) => c.id === id);
  const getSortedDocs = (docs) => _.orderBy(docs, ['serviceAt', 'updatedAt'], sortOrder);

  useInterval((skip = 0, limit = pageSize) => {
    sendPatientInteractionsRequest(patientId, skip, limit, filters, false, (data) => {
      const diff = _.differenceWith(data, documents, (doc1, doc2) => doc1.id === doc2.id);

      if (_.isEmpty(diff)) return;

      setDocuments(getSortedDocs([...diff, ...documents]));
    });
  }, DOCUMENTATION_POLLING_FREQUENCY);

  const loadDocsForPatient = (patientId, skip, limit, filters) => {
    sendPatientInteractionsRequest(patientId, skip, limit, filters, true, (data) => {
      isEndReached.current = data.length === 0;
      setDocuments(skip === 0 ? data : getSortedDocs([...documents, ...data]));
    });
  };

  const loadNextPage = (skip, limit) => {
    if (isEndReached.current) return;
    loadDocsForPatient(patientId, skip, limit, filters);
  };

  const { handleScroll, from, setFrom } = usePagination(
    documents.length,
    pageSize,
    loadNextPage,
    false,
    false,
    isLoading,
  );

  // change filters side effect
  useEffect(() => {
    // always scroll to top in case of filters change
    if (scrollRef.current) scrollRef.current.scrollTop = 0;
    isEndReached.current = false;
    setTimeout(() => setFrom(0), 100);

    // send request with changed filters to load the first page
    if (from === 0) {
      sendPatientInteractionsRequest(patientId, 0, pageSize, filters, true, setDocuments);
    }
  }, [filters]);

  const renderItem = (item) => {
    const { serviceAt, reasons, id } = item;
    return (
      <DocumentationItem
        id={id}
        date={serviceAt}
        type={reasons.join(', ')}
        client={getClient(item.clientId)}
        onClick={() => {
          setSelectedDocument(item);
          updatePrevTabInfo('document', item);
        }}
        isSelected={selectedDocument?.id === id}
        textToHighlight={filters?.search}
      />
    );
  };

  return (
    <div className={styles.container}>
      <div ref={scrollRef} className={styles.sidebar} onScroll={handleScroll}>
        <List
          items={parentDocuments}
          renderItem={renderItem}
          emptyText={!isLoading && _.isEmpty(documents) ? 'No documentation' : ''}
          size={1}
        />
        {isLoading && (
          <CircleLoader
            style={{ margin: 0 }}
            color={ColorsNew.darkGreen}
            strokeWidth={2}
            circleRadius={9}
            label="Retrieving more documents"
          />
        )}
      </div>
      <SeparatorLine vertical />
      <div className={styles.content}>
        <DocumentationContent
          patientId={patientId}
          documentation={selectedDocument}
          client={getClient(selectedDocument?.clientId)}
          textToHighlight={filters?.search}
          allAddendums={addendums}
          clients={clients}
        />
      </div>
    </div>
  );
};

DocumentationTab.propTypes = {
  patientId: PropTypes.number,
  filters: PropTypes.object,
  selectedDocument: PropTypes.exact(PatientInteraction.rawSchema),
  setSelectedDocument: PropTypes.func,
  updatePrevTabInfo: PropTypes.func,
};

export default DocumentationTab;
