import _ from 'lodash';

import { roles } from './constants';

export const sortByName = (client1, client2) => {
  const client1Name = `${client1?.firstName || ''} ${client1?.lastName || ''}`;
  const client2Name = `${client2?.firstName || ''} ${client2?.lastName || ''}`;

  return client1Name.localeCompare(client2Name);
};

export const getClientOptions = (clients) => {
  if (_.isEmpty(clients)) return [];

  return clients.map((client) => ({
    value: client.id,
    label: typeof client.getName === 'function' ? client.getName() : '-',
  }));
};

export const clientsSearch = (clients, searchText) =>
  clients
    .filter((client) => {
      if (!searchText || !client) return true;

      const searchValue = searchText.toLowerCase();
      const firstName = client.firstName.toLowerCase();
      const lastName = client.lastName.toLowerCase();

      return (
        `${client.id}`.includes(searchValue) ||
        firstName.includes(searchValue) ||
        lastName.includes(searchValue) ||
        `${firstName} ${lastName}`.includes(searchValue) ||
        client.email.toLowerCase().includes(searchValue)
      );
    })
    .sort(sortByName);

export const getRoleById = (id) =>
  Object.values(roles).find((role) => role.id === id)?.label || 'unknown';
