import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Select } from 'common-src/components/base';
import { getRoleConfig } from 'common-src/features/auth';
import {
  getAccountabilityCoaches,
  getCDCESs,
  getClientOptions,
  getRegisteredDietitians,
  getSocialWorkers,
} from 'common-src/models/Client';
import Patient, { updatePatient } from 'common-src/models/Patient';
import { singleModelSelector } from 'common-src/utils/selectorUtils';

import useAlert from 'src/hooks/useAlert';
import useCustomSelector from 'src/hooks/useCustomSelector';
import useFormButtons from 'src/hooks/useFormButtons';

import styles from './AssignedProviders.module.scss';

const AssignedProviders = ({ patient }) => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({});

  const accountabilityCoaches = useCustomSelector((state) => getAccountabilityCoaches(state));
  const registeredDietitians = useCustomSelector((state) => getRegisteredDietitians(state));
  const CDCESs = useCustomSelector((state) => getCDCESs(state));
  const socialWorkers = useCustomSelector((state) => getSocialWorkers(state));
  const hasPermission = useCustomSelector(
    (state) => getRoleConfig(state)?.patientChart?.sections?.physicianOrders?.setAssignedProviders,
  );
  const assignedAC = useCustomSelector((state) =>
    singleModelSelector(state, 'Client', patient.assignedCoachId),
  );
  const assignedCDCES = useCustomSelector((state) =>
    singleModelSelector(state, 'Client', patient.assignedCDCESId),
  );
  const assignedRD = useCustomSelector((state) =>
    singleModelSelector(state, 'Client', patient.assignedDietitianId),
  );
  const assignedLcsw = useCustomSelector((state) =>
    singleModelSelector(state, 'Client', patient.assignedLcswId),
  );

  const { showAlert, AlertType } = useAlert();

  const onSubmitHandler = (setIsLoading, setIsEditMode) => {
    setIsLoading(true);
    dispatch(
      updatePatient(
        patient.id,
        {
          assignedCoachId: formData?.assignedAC?.value || null,
          assignedCDCESId: formData?.assignedCDCES?.value || null,
          assignedDietitianId: formData?.assignedRD?.value || null,
          assignedLcswId: formData?.assignedLcsw?.value || null,
        },
        {
          successBlock: () => {
            setIsEditMode(false);
            setIsLoading(false);
            showAlert(AlertType.Success, '', 'You have successfully updated assigned providers.');
          },
          errorBlock: (err) => {
            setIsLoading(false);
            showAlert(AlertType.Error, '', err);
          },
        },
      ),
    );
  };

  const { isEditMode, renderContent, setInitialData, setIsLoading, setIsEditMode } = useFormButtons(
    'section',
    setFormData,
    () => onSubmitHandler(setIsLoading, setIsEditMode),
    formData,
    true,
  );

  const getClientValue = (client) => {
    if (_.isEmpty(client)) return null;

    return {
      value: client.id,
      label: client.getName(false, true),
    };
  };

  useEffect(() => {
    const data = {
      assignedAC: getClientValue(assignedAC),
      assignedCDCES: getClientValue(assignedCDCES),
      assignedRD: getClientValue(assignedRD),
      assignedLcsw: getClientValue(assignedLcsw),
    };
    setInitialData(data);
    setFormData(data);
  }, [assignedAC, assignedCDCES, assignedRD]);

  const onChangeHandler = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const renderForm = () => (
    <div className={styles.form}>
      <Select
        id="accountability-coach-select"
        label="Coach"
        placeholder="Not assigned"
        options={getClientOptions(accountabilityCoaches)}
        onChange={(op) => onChangeHandler('assignedAC', op)}
        value={formData.assignedAC}
        readOnly={!isEditMode}
        isClearable
        position="absolute"
        size="small"
        emptyReadOnlyText="Not assigned"
      />
      <Select
        id="cdces-select"
        label="CDCES"
        placeholder="Not assigned"
        options={getClientOptions(CDCESs)}
        onChange={(op) => onChangeHandler('assignedCDCES', op)}
        value={formData.assignedCDCES}
        readOnly={!isEditMode}
        isClearable
        position="absolute"
        size="small"
        emptyReadOnlyText="Not assigned"
      />
      <Select
        id="registered-dietitian-select"
        label="RD"
        placeholder="Not assigned"
        options={getClientOptions(registeredDietitians)}
        onChange={(op) => onChangeHandler('assignedRD', op)}
        value={formData.assignedRD}
        readOnly={!isEditMode}
        isClearable
        position="absolute"
        size="small"
        emptyReadOnlyText="Not assigned"
      />
      <Select
        id="lcsw-select"
        label="Social worker"
        placeholder="Not assigned"
        options={getClientOptions(socialWorkers)}
        onChange={(op) => onChangeHandler('assignedLcsw', op)}
        value={formData.assignedLcsw}
        readOnly={!isEditMode}
        isClearable
        position="absolute"
        size="small"
        emptyReadOnlyText="Not assigned"
      />
    </div>
  );

  return (
    <>
      {renderForm()}
      {renderContent(false, !hasPermission)}
    </>
  );
};

AssignedProviders.propTypes = {
  patient: PropTypes.exact(Patient.schema),
};

export default AssignedProviders;
