import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './TimeItem.module.scss';

const TimeItem = ({ date, duration, timeZone }) => {
  const dateWithTimeZone = moment(date).tz(timeZone);
  const timeFrom = dateWithTimeZone.format('hh:mma z');
  const timeTo = dateWithTimeZone.add(duration, 'minutes').format('hh:mma z');
  const time = duration ? `${timeFrom} - ${timeTo}` : timeFrom;

  return (
    <div className={['gap-2', 'flex-column'].join(' ')}>
      <span className={[styles.date, 'font-w-500', 'font-s-14'].join(' ')}>
        {dateWithTimeZone.format('MM/DD/YYYY')}
      </span>
      <span className={[styles.time, 'font-w-500', 'font-s-12'].join(' ')}>{time}</span>
    </div>
  );
};

TimeItem.propTypes = {
  date: PropTypes.string,
  duration: PropTypes.number,
  timeZone: PropTypes.string,
};

export default TimeItem;
