export const PatientTaskStatus = Object.freeze({
  Scheduled: { value: 0, label: 'Scheduled', textColor: '#FFFFFF', backgroundColor: '#9D97C7' },
  New: { value: 1, label: 'New', textColor: '#FFFFFF', backgroundColor: '#9D97C7' },
  InProgress: { value: 2, label: 'In Progress', textColor: '#FFFFFF', backgroundColor: '#F1BF72' },
  Completed: { value: 3, label: 'Completed', textColor: '#FFFFFF', backgroundColor: '#637768' },
  Canceled: { value: 4, label: 'Canceled', textColor: '#FFFFFF', backgroundColor: '#F01F51B2' },
  UnableToComplete: {
    value: 5,
    label: 'Unable to Complete',
    textColor: '#FFFFFF',
    backgroundColor: '#F01F51B2',
  },
});

export const ActivePatientTaskStatuses = [
  PatientTaskStatus.Scheduled.value,
  PatientTaskStatus.New.value,
  PatientTaskStatus.InProgress.value,
];

// Types
export const ALL_TASK_TYPES = Object.freeze({
  value: '',
  label: 'All Types',
});

export const TaskType = Object.freeze({
  SocialWorkReferral: { value: 1, label: 'Social Work Referral' },
  Administrative: { value: 2, label: 'Administrative' },
  Diabetes: { value: 3, label: 'Diabetes' },
  Compliance: { value: 4, label: 'Compliance' },
  ScheduleVisit: { value: 5, label: 'Schedule Visit' },
});

// Reasons
export const ALL_TASK_TYPE_REASONS = Object.freeze({
  value: '',
  label: 'All Reasons',
});

export const TaskReason = Object.freeze({
  NewReferral: { value: 1, label: 'New Referral' },
  NewIssue: { value: 2, label: 'New Issue' },
  NeedsRpmApproval: { value: 3, label: 'Needs Rpm Approval' },
  AccountClosureNeeded: { value: 4, label: 'Account Closure Needed' },
  MFARequest: { value: 5, label: 'MFA Request' },
  CommercialPlanReview: { value: 6, label: 'Commercial Plan Review' },
  HypoglycemicRisk: {
    value: 7,
    label: 'Chart indicates increased risk of hypoglycemic events over the last 30 days',
  },
  Biannual: { value: 8, label: 'Chart indicates that member is due for a bi-annual visit' },
  MFARenewal: { value: 9, label: 'Member’s MFA is about to expire' },
  CGMAssessment: { value: 10, label: 'CGM Assessment needed' },
  InteractionAdjustments: { value: 11, label: 'Interaction Adjustments' },
});

export const CREATABLE_TASK_TYPE_REASONS = [TaskReason.AccountClosureNeeded];
