import { restRequest } from 'common-src/features/rest/actions';
import { HttpMethods } from 'common-src/features/rest/constants';

export const getPatientOncehubBookingsRequest = (query, params) =>
  restRequest({
    uri: 'patientOncehubBookings',
    query,
    method: HttpMethods.Get,
    ...params,
  });

export const updateOncehubBookingStatus = (id, status, params) =>
  restRequest({
    uri: `patientOncehubBookings/${id}/status`,
    body: { status },
    method: HttpMethods.Put,
    ...params,
  });
