import _ from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

import { CommonIcons } from 'common-src/assets/Icons';
import {
  Checkbox,
  DatePickerNew,
  FormSelect,
  Input,
  RadioGroup,
  SingleChoice,
  TextArea,
} from 'common-src/components/base';
import { isFeatureEnabled } from 'common-src/models/FeatureFlag';

import useCustomSelector from 'src/hooks/useCustomSelector';
import {
  CGMAlertsForm,
  CGMEvaluationForm,
  VitalsAndLabsForm,
  WellnessPlanForm,
} from 'src/pages/DocumentationIndex/components';
import {
  getFieldKey,
  shouldExpandContent,
  shouldRenderLabel,
  textWithMarkdown,
} from 'src/pages/DocumentationIndex/helpers';

import commonStyles from '../commonStyles.module.scss';
import FormHeader from '../FormHeader';
import {
  CGMAlertsModules,
  CGMEvaluationModules,
  VitalsAndLabsModules,
  WellnessPlanModules,
} from './constants';
import styles from './DocumentForm.module.scss';

const QUESTIONS_WITH_MIN_DATE = [
  'Please enter starting date for hold status',
  'Please enter expiration date for hold status',
];

const DocumentForm = ({
  patientId,
  modules,
  setModules,
  primaryModuleTypeId,
  setHasDetectedChanges,
  setPrimaryModuleTypeId,
  errors,
  handleError,
  vitalsAndLabs,
  setVitalsAndLabs,
  wellnessPlan,
  setWellnessPlan,
  cgmAlerts,
  setCgmAlerts,
  cgmEvaluation,
  setCgmEvaluation,
  classNames = [],
  showPrimaryModuleCheckbox = false,
}) => {
  const isCGMAssessmentEnabled = useCustomSelector((state) =>
    isFeatureEnabled(state, 'cgmAssessment'),
  );

  const getFieldErrors = (key) => {
    const questionKey = key.replace(/\.value$/, '');
    const question = _.get(modules, questionKey);

    if (!question) return null;

    const hasError = errors?.has(question.id);
    if (!hasError) return null;

    errors.delete(question.id);

    return errors;
  };

  const updateModuleHandler = (key, value) => {
    const fieldErrors = getFieldErrors(key);
    if (fieldErrors) {
      handleError({ generalError: null, fieldErrors });
    }

    const modulesCopy = JSON.parse(JSON.stringify(modules));
    const newModules = _.set(modulesCopy, key, value);
    setModules(newModules);
    setHasDetectedChanges(new Date());
  };

  const renderSectionHeader = ({ header, instructions }) => (
    <>
      {!!header && <p className={[styles.header, 'font-w-700'].join(' ')}>{header}</p>}
      <p
        className={[
          styles.instructions,
          'font-w-500',
          'font-s-14',
          !instructions ? styles.empty : '',
        ].join(' ')}
      >
        {instructions ? textWithMarkdown(instructions, '', styles.linkedText) : ''}
      </p>
    </>
  );

  const renderQuestionContent = (key, question, id) => {
    const hasError = errors?.has(question.id);
    const errorText = errors?.get(question.id);

    switch (question.type) {
      case 'integer':
      case 'currency':
      case 'number': {
        return (
          <Input
            id={id}
            classNames={[styles.number]}
            inputClassNames={[commonStyles.inputNumber]}
            type="number"
            label={question.text}
            description={question?.label || null}
            placeholder={question?.placeholder || ''}
            required={question.required}
            value={_.get(modules, key)}
            onFinishEditing={(value) => updateModuleHandler(key, value)}
            debounced
            isFloat={question.type === 'number'}
            withError={hasError}
            errorText={errorText}
            prefix={question.type === 'currency' ? '$' : null}
          />
        );
      }
      case 'text':
      case 'textArea': {
        let maxRows;
        let fixedRows;
        switch (question?.maxChars) {
          case 255:
            maxRows = 8;
            fixedRows = 3;
            break;
          case 512:
            maxRows = 16;
            fixedRows = 6;
            break;
          case 10000:
            maxRows = 30;
            fixedRows = 10;
            break;
          default:
            maxRows = 8;
            fixedRows = null;
            break;
        }

        return (
          <TextArea
            id={id}
            classNames={[styles.input]}
            inputClassNames={[commonStyles.inputText]}
            label={question.text}
            description={question?.label || null}
            placeholder={question.placeholder || ''}
            required={question.required}
            value={_.get(modules, key)}
            maxRows={maxRows}
            fixedRows={fixedRows}
            onFinishEditing={(value) => updateModuleHandler(key, value)}
            debounced
            errorText={errorText}
          />
        );
      }
      case 'bool': {
        const value = _.get(modules, key);
        return (
          <SingleChoice
            classNames={[styles.singleChoice]}
            radioClassnames={[styles.radio, styles.radioDirection]}
            value={value}
            label={question.text || ''}
            onClear={() => updateModuleHandler(key, null)}
            onChange={(value) => updateModuleHandler(key, value)}
          />
        );
      }
      case 'checkbox': {
        const selectedValues = _.get(modules, key) || [];
        const checkboxClassNames = [commonStyles.checkbox];
        const containerClassNames = [];
        if (question.orientation === 'row') {
          containerClassNames.push('flex-row gap-16 vertically-centered');
        } else {
          checkboxClassNames.push('m-b-14');
          containerClassNames.push('flex-column vertically-start');
        }

        const choices = question?.choices || [];

        return (
          <div className={containerClassNames.join(' ')}>
            {choices.map((choice, index) => {
              const optionKey = `${key}.${index}`;
              return (
                <Checkbox
                  key={optionKey}
                  id={`${id}.${index}`}
                  classNames={checkboxClassNames}
                  label={choice}
                  checked={selectedValues?.includes(choice)}
                  onChange={() =>
                    updateModuleHandler(
                      key,
                      selectedValues?.includes(choice)
                        ? selectedValues?.filter((value) => value !== choice)
                        : [...selectedValues, choice],
                    )
                  }
                />
              );
            })}
          </div>
        );
      }
      case 'radio': {
        const selectedValue = _.get(modules, key);

        const radioClassNames = [styles.radio];
        const containerClassNames = [];
        if (question.orientation === 'row') {
          radioClassNames.push(styles.row);
          containerClassNames.push('flex-row gap-16 vertically-centered');
        } else {
          containerClassNames.push('flex-column vertically-start');
        }

        return (
          <RadioGroup
            id={id}
            containerClassNames={containerClassNames}
            classNames={radioClassNames}
            options={question.choices}
            value={selectedValue || null}
            onChange={(value) => updateModuleHandler(key, value)}
            onClear={() => updateModuleHandler(key, null)}
          />
        );
      }
      case 'date': {
        const rawValue = _.get(modules, key);
        const value = !_.isEmpty(rawValue) ? moment(rawValue) : null;

        return (
          <DatePickerNew
            id={id}
            placeholder={question.placeholder || 'Select date'}
            value={value}
            minDate={QUESTIONS_WITH_MIN_DATE.includes(question) ? new Date() : undefined}
            required={question.required}
            iconSrc={CommonIcons.calendarCheckBoldIcon}
            onDateSelected={(date) => {
              let dateToApply = '';
              if (date) {
                dateToApply = moment(date).format('MM/DD/YYYY');
              }
              return updateModuleHandler(key, dateToApply);
            }}
            classNames={[styles.date, commonStyles.date]}
            inputClassNames={[commonStyles.inputDateContainer]}
            textClassNames={[commonStyles.dateInput]}
            errorText={errorText}
          />
        );
      }
      case 'label': {
        return (
          <p
            className={[styles.label, hasError ? styles.withError : ''].join(' ')}
            data-end={question.required ? '*' : ''}
          >
            {question.text}
          </p>
        );
      }
      case 'multiSelect': {
        const options = question.choices?.map((choice) => ({ value: choice, label: choice }));
        const value = options.filter((option) => question.value?.includes(option.value));

        return (
          <FormSelect
            id={id}
            classNames={[styles.multiSelect]}
            label={question.text}
            placeholder={question?.placeholder || null}
            description={question?.label || null}
            options={options}
            value={value}
            onChange={(selectedOptions) => {
              updateModuleHandler(
                key,
                selectedOptions.map((option) => option.value),
              );
            }}
            required={question.required}
            withError={hasError}
            errorText={errorText}
            multiple
          />
        );
      }
      default:
        return <p>RESPONSE TYPE {question.type} NOT IMPLEMENTED</p>;
    }
  };

  const renderSectionQuestion = (sections, { contents }, moduleIdx, sectionIdx, moduleType) => {
    if (_.isEmpty(contents)) return null;

    return contents.map((question, questionIdx) => {
      if (!shouldExpandContent(sections, question)) return null;

      const hasError = errors?.has(question.id);

      return (
        <div
          key={question.id ? String(question.id) : `${question.text}-${sectionIdx}-${questionIdx}`}
          id={question.id}
          className={[
            styles.questions,
            ['text', 'textArea', 'label'].includes(question.type) ? 'p-b-12' : '',
          ].join(' ')}
        >
          {shouldRenderLabel(question) && (
            <p
              className={[
                styles.label,
                hasError ? styles.withError : '',
                ['radio', 'checkbox'].includes(question.type) ? 'm-b-16' : 'm-b-8',
              ].join(' ')}
              data-end={question.required ? '*' : ''}
            >
              {question.text}
            </p>
          )}
          {renderQuestionContent(
            getFieldKey(modules, moduleIdx, sectionIdx, questionIdx),
            question,
            `${moduleType}.section.${sectionIdx}.question.${questionIdx}.value`,
          )}
        </div>
      );
    });
  };

  const renderContent = (module, moduleIdx) => {
    const { isExpanded, sections, moduleType } = module;

    if (!isExpanded) return null;

    return sections.map((s, i) => {
      const shouldExpand = shouldExpandContent(sections, s);

      if (!shouldExpand) return null;

      return (
        <div key={s.id} id={`question-${moduleType}-${i}`} className={styles.section}>
          {renderSectionHeader(s)}
          {renderSectionQuestion(sections, s, moduleIdx, i, moduleType)}
        </div>
      );
    });
  };

  const renderHeader = (module) => {
    const { id, title, isExpanded, moduleType, instructions } = module;
    const index = _.findIndex(modules, ['id', id]);

    return (
      <>
        <FormHeader
          title={title}
          isExpanded={!!isExpanded}
          onClick={() => {
            updateModuleHandler(`${index}.isExpanded`, !isExpanded);
            setHasDetectedChanges(new Date());
          }}
          showPrimaryModuleCheckbox={showPrimaryModuleCheckbox}
          onChange={() => {
            setPrimaryModuleTypeId(moduleType);
            setHasDetectedChanges(new Date());
          }}
          moduleType={moduleType}
          primaryModuleTypeId={primaryModuleTypeId}
        />
        {instructions && isExpanded && (
          <div className={[styles.moduleInstructions, 'font-s-14', 'p-l-30', 'm-b-20'].join(' ')}>
            {instructions}
          </div>
        )}
      </>
    );
  };

  const renderVitalsAndLabs = (module) => {
    if (!patientId || !VitalsAndLabsModules.includes(module.moduleType) || !module.isExpanded)
      return null;

    return (
      <VitalsAndLabsForm
        patientId={patientId}
        vitalsAndLabs={vitalsAndLabs}
        setVitalsAndLabs={setVitalsAndLabs}
        errors={errors}
        setHasDetectedChanges={setHasDetectedChanges}
      />
    );
  };

  const renderWellnessPlan = (module) => {
    if (!patientId || !WellnessPlanModules.includes(module.moduleType) || !module.isExpanded)
      return null;

    return (
      <WellnessPlanForm
        wellnessPlan={wellnessPlan}
        setWellnessPlan={setWellnessPlan}
        setHasDetectedChanges={setHasDetectedChanges}
      />
    );
  };

  const renderCGMEvaluation = (module) => {
    if (
      !patientId ||
      !CGMEvaluationModules.includes(module.moduleType) ||
      !module.isExpanded ||
      !isCGMAssessmentEnabled
    )
      return null;

    return (
      <CGMEvaluationForm
        patientId={patientId}
        cgmEvaluation={cgmEvaluation}
        setCgmEvaluation={setCgmEvaluation}
        errors={errors}
        setHasDetectedChanges={setHasDetectedChanges}
      />
    );
  };

  const renderCGMAlerts = (module) => {
    if (
      !patientId ||
      !CGMAlertsModules.includes(module.moduleType) ||
      !module.isExpanded ||
      !isCGMAssessmentEnabled
    )
      return null;

    return (
      <CGMAlertsForm
        cgmAlerts={cgmAlerts}
        setCgmAlerts={setCgmAlerts}
        setHasDetectedChanges={setHasDetectedChanges}
      />
    );
  };

  return modules.map(
    (module, moduleIdx) =>
      module.isSelected && (
        <div
          id={`module-${module.moduleType}`}
          key={module.title}
          className={[...classNames].join(' ')}
        >
          {renderHeader(module)}
          {renderVitalsAndLabs(module)}
          {renderWellnessPlan(module)}
          {renderCGMEvaluation(module)}
          {renderCGMAlerts(module)}
          {renderContent(module, moduleIdx)}
        </div>
      ),
  );
};

DocumentForm.propTypes = {
  patientId: PropTypes.number,
  modules: PropTypes.arrayOf(PropTypes.object),
  setModules: PropTypes.func,
  primaryModuleTypeId: PropTypes.number,
  setPrimaryModuleTypeId: PropTypes.func,
  showPrimaryModuleCheckbox: PropTypes.bool,
  errors: PropTypes.object,
  classNames: PropTypes.arrayOf(PropTypes.string),
  handleError: PropTypes.func,
  vitalsAndLabs: PropTypes.object,
  setVitalsAndLabs: PropTypes.func,
  cgmAlerts: PropTypes.object,
  setCgmAlerts: PropTypes.func,
  cgmEvaluation: PropTypes.object,
  setCgmEvaluation: PropTypes.func,
};

export default DocumentForm;
